import State from '@/state/State';

export const toCurrency = (value, currency) => {
  if (currency == null && State.state?.company?.default_currency) {
    currency = State.state.company.default_currency;
  }

  switch (currency) {
    case 'EUR':
      return new Intl.NumberFormat('en-UK', {
        style: 'currency',
        currency: 'EUR',
        minimumFractionDigits: 2,
      }).format(value / 100);
    case 'USD':
      return new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: 2,
      }).format(value / 100);
    case 'AUD':
      return new Intl.NumberFormat('en-AU', {
        style: 'currency',
        currency: 'AUD',
        minimumFractionDigits: 2,
      }).format(value / 100);
    default:
      return new Intl.NumberFormat('en-UK', {
        style: 'currency',
        currency: 'GBP',
        minimumFractionDigits: 2,
      }).format(value / 100);
  }
};

export const toRawCurrency = value => {
  return new Intl.NumberFormat('en-UK', {
    style: 'currency',
    currency: 'GBP',
    currencyDisplay: 'code',
    minimumFractionDigits: 2,
  })
    .format(value / 100)
    .replace('GBP', '')
    .trim();
};

export const toCompactCurrency = value => {
  let currency = 'GBP';

  if (State.state?.company?.default_currency) {
    currency = State.state.company.default_currency;
  }

  switch (currency) {
    case 'USD':
      return new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        maximumFractionDigits: 0,
      }).format(value / 100);
    default:
      return new Intl.NumberFormat('en-UK', {
        style: 'currency',
        currency: 'GBP',
        maximumFractionDigits: 0,
      }).format(value / 100);
  }
};

export const getUtmParams = () => {
  const urlParams = new URLSearchParams(window.location.search);
  const params = {};
  for (const [key, value] of urlParams) {
    if (key.startsWith('utm_')) {
      params[key] = value;
    }
  }
  return params;
};

<template>
  <div>
    <div class="row">
      <div class="col-md-6">
        <h3>Last Day Pay</h3>
        <ol>
          <!--
            Different partnerships have specific content for the list below,
            the implementation assumes using partnership title as a key
            to the related content.
          -->
          <li
            v-for="(bullet, i) in Array.isArray(GUIDANCE_BULLETS[partnership])
              ? GUIDANCE_BULLETS[partnership]
              : GUIDANCE_BULLETS['default']"
            :key="i"
          >
            {{ bullet }}
          </li>
          <p class="success-line">Routing Number: {{ employee.properties.payment_sort_code || 'No routing No.' }}</p>
          <p class="success-line">
            Account Number: {{ employee.properties.payment_account_number || 'No account No.' }}
          </p>
        </ol>
      </div>

      <div class="col-md-6">
        <div class="last-day-pay__title">
          Total Debt:
          <span
            >${{
              isFinalPayConfirmed
                ? toDecimalNotation(employee.properties.final_total_debt / 100)
                : toDecimalNotation(totalDebt)
            }}</span
          >
        </div>

        <div v-if="isFinalPayConfirmed">
          <div class="last-day-pay__title">
            Final Net Pay:
            <span>${{ toDecimalNotation(employee.properties.final_net_pay_amount / 100) }} </span>
          </div>
          <p>This is the net salary from the employee's final paycheck</p>
        </div>
        <form v-else class="last-day-pay__form">
          <b-form-group class="last-day-pay__form-group">
            <label for="final-net-pay-input">Final Net Pay:</label>
            <b-input-group>
              <b-input-group-prepend>
                <div class="input-group-text">
                  <font-awesome-icon :icon="['fa', 'dollar-sign']" />
                </div>
              </b-input-group-prepend>

              <b-form-input
                id="final-net-pay-input"
                type="number"
                v-model="finalNetPayValue"
                :min="totalDebt"
                :max="maxFinalNetPayValue"
                step="0.01"
                debounce="500"
              ></b-form-input>
            </b-input-group>
          </b-form-group>
          <p>This is the net salary from the employee's final paycheck</p>
        </form>

        <div class="last-day-pay__title">
          Final Pay:
          <span
            >${{
              isFinalPayConfirmed
                ? toDecimalNotation(employee.properties.final_employee_salary / 100)
                : toDecimalNotation(finalPay)
            }}
          </span>
        </div>
        <p>
          <span>This is the amount the user will receive.</span>
          <span v-if="!isFinalPayConfirmed"
            >Wagestream will expect the full net pay of ${{ toDecimalNotation(finalNetPayValue) }}</span
          >
        </p>

        <sme-alert v-if="isFinalPayConfirmed" level="good" icon="check">
          <p class="success-line _heading">Final pay has been processed</p>
          <p class="success-line _marked">Next steps</p>
          <ul class="next-steps">
            <li>
              Transfer the user's final net pay of ${{
                toDecimalNotation(employee.properties.final_employee_salary / 100)
              }}
              to the following Wagestream account
            </li>
            <li>Routing Number: {{ employee.properties.payment_sort_code || 'No routing No.' }}</li>
            <li>Account Number: {{ employee.properties.payment_account_number || 'No account No.' }}</li>
          </ul>
        </sme-alert>
        <sme-alert v-else-if="isError" level="danger" icon="times-circle">{{ errMessage }}</sme-alert>

        <div v-if="!isFinalPayConfirmed" class="col-md-12 mt-5 d-flex send-action">
          <b-button
            @click="onSubmit"
            variant="primary"
            :disabled="+finalNetPayValue < +totalDebt || !+finalNetPayValue"
          >
            Confirm payment
          </b-button>
        </div>
      </div>
    </div>
    <b-modal
      v-model="showModal"
      ok-title="Confirm"
      ok-variant="danger"
      title="Confirm Last Day Pay"
      body-class="pt-0"
      size="lg"
      centered
      @cancel="onPopupClose"
      @ok="onTransferConfirm"
    >
      <!-- icon="money-check-alt" -->
      <div class="summary__header">
        <font-awesome-icon :icon="['fad', 'money-check-alt']" class="summary__icon" />
      </div>
      <AppLoading :loading="isRequestInProgress" />
      <div class="summary__subtitle">
        <div>You won't be able to undo this action</div>
      </div>
      <div class="summary__row">
        <div>Final net pay</div>
        <div>{{ toDecimalNotation(finalNetPayValue) }}</div>
      </div>
      <div class="summary__row">
        <div>Total debt</div>
        <div>- {{ toDecimalNotation(totalDebt) }}</div>
      </div>
      <div class="summary__row _total">
        <div>Final pay</div>
        <div>{{ toDecimalNotation(finalPay) }}</div>
      </div>
    </b-modal>
  </div>
</template>

<script setup>
const GUIDANCE_BULLETS = {
  lip: [
    "Calculate the employee's final net pay in your payroll system and enter it in the field 'Final Net Pay' to the right.",
    'Click Confirm Payment.',
    "Legion InstantPay will subtract any outstanding Legion InstantPay transfers & fees (Total Debt) and send the remaining (Final Pay) to the employee's bank account.",
    'Be sure to include this employee in your regular payroll run, as that is how Legion InstantPay is reimbursed (pay will go to their Legion InstantPay account, not to the employee).',
    'Please send payment to:',
  ],
  osv: [
    "Calculate the employee's final net pay in your payroll system and enter it in the field 'Final Net Pay' to the right.",
    'Click Confirm Payment.',
    "myFlexPay will subtract any outstanding myFlexPay transfers & fees (Total Debt) and send the remaining (Final Pay) to the employee's bank account.",
    'Send the employees entire net pay to the myFlexPay payment election on the employees payroll account during your normal payroll process.',
    'Please send payment to:',
  ],
  frontline: [
    "Calculate the employee's final net pay in your payroll system and enter it in the field 'Final Net Pay' to the right.",
    'Click Confirm Payment.',
    "Wagestream will subtract any outstanding transfers & fees (Total Debt) and send the remaining (Final Pay) to the employee's bank account.",
    'Be sure to include this employee in your regular payroll run, as that is how Wagestream is reimbursed (pay will go to their Wagestream account, not to the employee).',
    'Please send payment to:',
  ],
  wagestream: [
    "Calculate the employee's final net pay in your payroll system and enter it in the field 'Final Net Pay' to the right.",
    'Click Confirm Payment.',
    "Wagestream will subtract any outstanding transfers & fees (Total Debt) and send the remaining (Final Pay) to the employee's bank account.",
    'Be sure to include this employee in your regular payroll run, as that is how Wagestream is reimbursed (pay will go to their Wagestream account, not to the employee).',
    'Please send payment to:',
  ],
  default: [
    "Calculate the employee's final net pay in your payroll system and enter it in the field 'Final Net Pay' to the right.",
    'Click Confirm Payment.',
    "Wagestream will subtract any outstanding transfers & fees (Total Debt) and send the remaining (Final Pay) to the employee's bank account.",
    'Be sure to include this employee in your regular payroll run, as that is how Wagestream is reimbursed (pay will go to their Wagestream account, not to the employee).',
    'Please send payment to:',
  ],
};
</script>

<script>
import uuidv4 from 'uuid/v4';
import ApiClient from '@/ApiClient';
import { getPartnership } from '@/utils/partnership';
import AppLoading from '@/components/AppLoading.vue';
import SmeAlert from '@/components/atoms/SmeAlert.vue';
import { LAST_DAY_PAY_UPPER_BOUND } from '@/pages/employee-payments/constants';

export default {
  name: 'EmployeeLastDayPay',
  model: {
    prop: 'employee',
    event: 'input',
  },
  props: {
    employee: Object,
    balance: Object,
    currency: String,
  },
  components: { SmeAlert },
  data() {
    return {
      finalNetPayValue: +this.balance.total_debt / 100,
      showModal: false,
      isError: false,
      errMessage: null,
      isRequestInProgress: false,
      maxFinalNetPayValue: LAST_DAY_PAY_UPPER_BOUND,
      partnership: getPartnership(),
    };
  },
  methods: {
    async onTransferConfirm() {
      this.isRequestInProgress = true;
      const payload = {
        transfer_id: uuidv4(),
        employee_id: this.employee.employee_id,
        currency: this.currency,
        final_net_pay: Math.round(this.finalNetPayValue * 100),
      };

      try {
        const { data: { error } = {} } = await ApiClient.postLastDayPay(payload);
        if (error) {
          throw error;
        }

        this.$emit('finalPayProcessed');
      } catch (err) {
        this.isError = true;
        this.errMessage = err?.message || 'Error occured';
      } finally {
        this.isRequestInProgress = false;
        this.showModal = false;
      }
    },
    onPopupClose() {
      this.showModal = false;
    },
    onSubmit() {
      this.showModal = true;
    },
  },
  computed: {
    totalDebt() {
      return +this.balance.total_outstanding / 100;
    },
    finalPay() {
      return +this.finalNetPayValue - +this.totalDebt;
    },
    toDecimalNotation() {
      return value => (+value ? (+value).toFixed(2) : '0.00');
    },
    isFinalPayConfirmed() {
      return this.employee.properties.final_pay;
    },
  },
  watch: {
    finalNetPayValue(inputValue) {
      if (inputValue > this.maxFinalNetPayValue) {
        this.finalNetPayValue = this.maxFinalNetPayValue;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
h3 {
  margin-bottom: 38px;
}

ol {
  margin: 0;
  padding-left: 0.75rem;
}

.last-day-pay {
  &__title {
    margin-bottom: 12px;
    font-size: 1.1rem;
  }

  &__form {
    margin-bottom: 26px;

    &-group {
      margin-bottom: 6px;
    }
  }
}

.success-line {
  margin: 0;

  &._heading {
    margin-bottom: 10px;
  }

  &._marked {
    font-weight: 700;
  }
}

.next-steps {
  padding-left: 25px;
}

.summary {
  &__row {
    display: flex;
    justify-content: space-between;
    color: var(--palette-color-brand-primary);
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;

    & + & {
      margin-top: 8px;
    }

    &._total {
      margin-top: 12px;
      padding-top: 10px;
      border-top: 1px solid var(--palette-color-base-background);
      color: var(--palette-color-brand-primary);
      font-weight: 700;
    }
  }

  &__header {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 12px;
  }

  &__subtitle {
    margin: 8px 0 0;
    color: var(--palette-color-danger);
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    text-align: center;
  }

  &__icon {
    margin-bottom: 5px;
    font-size: 3rem;
    color: var(--palette-color-warning);
  }

  &__content {
    width: 95%;
    max-width: 364px;
    height: max-content;
    margin-bottom: 42px;
    padding: 32px 20px;
    border-radius: 16px;
    overflow: hidden;
    box-shadow: 0px 0px 4px rgba(34, 40, 47, 0.1), 0px 8px 40px rgba(34, 40, 47, 0.2);
    background-color: var(--palette-color-core-1-lighten-90);
  }
}
</style>

import State from '@/state/State';
import { TODO_ACTIONS } from '@/utils/Todo';

export const PERIODICITY = {
  WEEKLY: 'WEEKLY',
  FORTNIGHT: 'FORTNIGHT',
  FOUR_WEEKLY: 'FOUR_WEEKLY',
  MONTHLY: 'MONTHLY',
};

export const PERIODICITY_LABELS = {
  [PERIODICITY.WEEKLY]: 'Weekly',
  [PERIODICITY.FORTNIGHT]: 'Fortnight',
  [PERIODICITY.FOUR_WEEKLY]: 'Four weekly',
  [PERIODICITY.MONTHLY]: 'Monthly',
};

export const PERIODICITY_VALUES = {
  [PERIODICITY.WEEKLY]: 7,
  [PERIODICITY.FORTNIGHT]: 14,
  [PERIODICITY.FOUR_WEEKLY]: 28,
  [PERIODICITY.MONTHLY]: undefined,
};

export const SALARY_TYPE = {
  MIXED: 'mixed',
  HOURLY: 'hourly',
  SALARY: 'salary',
};

export const SALARY_TYPE_LABELS = {
  [SALARY_TYPE.MIXED]: 'Mixed',
  [SALARY_TYPE.HOURLY]: 'Shift',
  [SALARY_TYPE.SALARY]: 'Salaried',
};

export const isPayscheduleExpiring = payscheduleId =>
  State.state.todos.some(todo => todo.action === TODO_ACTIONS.UPDATE_PAYSCHEDULE && todo.fk === payscheduleId);

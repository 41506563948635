import { loadStripe } from '@stripe/stripe-js';
import cloneDeep from 'lodash/cloneDeep';
import uuidv4 from 'uuid/v4';
import ApiClient from '@/ApiClient';
import router from '@/Router';

const REGISTRATION_STEPS = [
  { name: 'LANDING', routeName: 'register' },
  { name: 'CREATE_USER', routeName: 'register-user' },
  { name: 'CREATE_COMPANY', routeName: 'register-company' },
  { name: 'ACCEPT_TERMS', routeName: 'register-terms' },
  { name: 'CREATE_SUBSCRIPTION', routeName: 'register-subscription' },
];

export const getCurrentRegistrationStep = () => {
  const currentStep = REGISTRATION_STEPS.find(step => {
    return router.resolve({ name: step.routeName })?.route.path === router.currentRoute.path.replace(/\/$/, '');
  });

  if (!currentStep) {
    throw new Error('Not a valid registration step');
  }

  return currentStep;
};

const defaultState = {
  //configuration
  registrationId: null,
  // user
  userFirstName: '',
  userLastName: '',
  userEmail: '',
  userPassword: '',
  userReferral: '',
  userPhone: '',
  // company
  companyNumber: null,
  companyName: '',
  //terms
  termsAccepted: false,
  diligenceAccepted: false,
  //subscription
  subscriptionPlanChosen: null,
  promoCode: null,
  stripeToken: null,
  stripeKey: null,
  //analytics
  via: null,
  rewardfulCode: null,
  couponCode: null,
  pv: null,
  acId: null, // active campaign ID
  referrerUrl: null,
  // set from server responses
  userId: null,
  companyId: null,
  payScheduleId: null,
  termsUrl: '',
  termsId: '',
  agreementId: null,
  subscriptionId: null,
  //provider
  provider: null,
  partnership: null,
  partnershipProperties: {},
};

export class RegistrationState {
  error = false;
  loading = false;
  state = cloneDeep(defaultState);
  save() {
    localStorage.setItem('wagestream.registration.state', JSON.stringify(this.state));
  }
  load() {
    const json = localStorage.getItem('wagestream.registration.state');
    if (json) {
      this.state = JSON.parse(json);
    }
    if (this.state.registrationId === null) {
      this.state.registrationId = localStorage.getItem('sme.lead.id') || uuidv4();
    }
    return this;
  }
  configure(configuration) {
    const { key, modules } = configuration;
    this.state.modules = modules;
    this.state.key = key;
  }
  async nextStep() {
    try {
      const currentStep = getCurrentRegistrationStep();
      const currentIndex = REGISTRATION_STEPS.indexOf(currentStep);

      if (currentIndex === REGISTRATION_STEPS.length - 1) {
        await this.setup();
      } else {
        router.replace({
          name: REGISTRATION_STEPS[currentIndex + 1].routeName,
          query: router.currentRoute.query,
        });
      }
    } catch {
      router.replace({ name: REGISTRATION_STEPS[0].routeName });
    }
  }
  async setup() {
    this.state = await ApiClient.smeSetup(this.state);
    this.save();
    await ApiClient.getUser();

    if (this.state.stripeKey && this.state.subscriptionPlanChosen) {
      const stripe = await loadStripe(this.state.stripeKey);
      const subscription = await ApiClient.smeSubscribe({
        subscriptionPlanChosen: this.state.subscriptionPlanChosen,
        promoCode: this.state.promoCode,
      });

      if (subscription.client_secret) {
        const result = await stripe.confirmCardPayment(subscription.client_secret, {
          payment_method: {
            card: {
              token: this.state.stripeToken.id,
            },
          },
        });

        if (result.error) {
          throw new Error(result.error);
        }
      }
    }

    this.state.userPassword = null;
    this.state.stripeToken = null;
    this.state.stripeKey = null;
    this.save();

    // put this in here so that it happens async
    window.location.replace(`${window.location.origin}/`);
  }
}

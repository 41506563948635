import moment from 'moment';

export const DEFAULT_DATE_FORMAT = 'YYYY-MM-DD';
export const DEFAULT_TIME_FORMAT = 'HH:mm';
export const US_DATE_FORMAT = 'MM/DD/YYYY';
export const US_TIME_FORMAT = 'hh:mm A';

export const toFormattedDate = (date, dateFormat = null) => {
  if (!date) {
    return date;
  }
  if (dateFormat != null) {
    return moment(date).format(dateFormat);
  }
  if (navigator.language === 'en-US') {
    return moment(date).format(US_DATE_FORMAT);
  }
  return moment(date).format(DEFAULT_DATE_FORMAT);
};

export const toFormattedDateTime = value => {
  if (navigator.language === 'en-US') {
    return moment(value).format(`${US_DATE_FORMAT} ${US_TIME_FORMAT}`);
  }
  return moment(value).format(`${DEFAULT_DATE_FORMAT} ${DEFAULT_TIME_FORMAT}`);
};

export const toFormattedDateAllowNulls = (date, dateFormat = DEFAULT_DATE_FORMAT) =>
  date ? moment(date).format(dateFormat) : null;

// finds days between 2 dates to 2 decimal places
export const daysBetweenDates = (startDate, endDate) =>
  Math.round(100 * ((endDate - startDate + 24 * 60 * 60 * 1000) / (24 * 60 * 60 * 1000))) / 100;

export const toDateFromNowText = date => (date ? moment.utc(date).fromNow() : '');

export const toMonthDescriptor = (date, monthPrefix = 'in ') => {
  const currentMonth = moment().month();
  const dateMonth = moment(date).month();

  if (currentMonth === dateMonth) {
    return 'this month';
  } else if (dateMonth === currentMonth - 1) {
    return 'last month';
  }

  return `${monthPrefix}${moment(date).format('MMMM')}`;
};

<template>
  <div>
    <app-loading :loading="!integrationsState.state.loaded" />
    <template v-if="integrationsState.state.loaded">
      <!--&& !error-->
      <div v-if="entity" class="panel-tag">Columns needed {{ displayColumns }}</div>

      <file-pond
        ref="file"
        :label-idle="emptyFileLabel"
        @addfile="onAddFile"
        @removefile="onRemoveFile"
        :accepted-file-types="acceptedFileTypes"
        file-validate-type-label-expected-types="Expects a CSV file"
      />

      <sme-alert level="danger" v-if="upload.validationErrors.invalidCharacters">
        <b>The file has invalid characters, please fix them before uploading the file</b>
        <br />
        Please fix the file or contact support
      </sme-alert>

      <slot name="extraErrors"></slot>
      <sme-alert level="danger" v-if="upload.validationErrors.missing">
        <b>Errors found in your csv column names:</b>
        <ul>
          <li>Missing columns: {{ upload.validationErrors.missing }}</li>
          <li v-if="upload.validationErrors.additional">
            Additional columns (not expected): {{ upload.validationErrors.additional }}
          </li>
        </ul>
        Please fix the column headers or contact support
      </sme-alert>

      <sme-alert level="danger" v-if="upload.validationErrors.validationReport">
        <b>Errors found in your csv data:</b>
        <ul>
          <li v-for="item in upload.validationErrors.validationReport" :key="item">
            {{ item }}
          </li>
        </ul>
        Please fix data errors or contact support
      </sme-alert>

      <sme-alert level="danger" v-if="badFileName">
        <b>Invalid file name</b>
        <br />
        <p>Only allowed file name values below:</p>
        <ul>
          <li v-for="item in upload.validationErrors.allowedFileNames" :key="item">
            {{ item }}
          </li>
        </ul>
        Please fix data errors or contact support
      </sme-alert>

      <sme-alert level="good" v-if="successfullyValidated">
        <p>Successful validation</p>
      </sme-alert>

      <sme-alert
        level="warning"
        icon="exclamation-triangle"
        v-if="!upload.validationErrors.missing && upload.validationErrors.additional"
      >
        <b>Additional columns (not expected): </b>
        <ul>
          <li>{{ upload.validationErrors.additional }}</li>
        </ul>
      </sme-alert>

      <div v-if="error" class="col-md-12 mt-3">
        <p class="alert alert-danger">{{ error }}</p>
      </div>

      <template
        v-if="
          upload.fileContent &&
          !upload.validationErrors.missing &&
          !upload.validationErrors.invalidCharacters &&
          !$slots.extraErrors
        "
      >
        <div class="panel-tag">
          You can now upload the file
          <ul v-if="!bypassValidation && entity">
            <li>Correct headers found</li>
            <li>Number of rows to upload: {{ rowsNumber }}</li>
          </ul>
        </div>
        <div class="d-flex">
          <b-button @click="uploadCsv" :disabled="uploading || metadataErrors || badFileName" variant="primary">
            Upload
          </b-button>
          <b-button
            class="ml-3"
            v-if="enableFileValidation"
            @click="validateCsv"
            :disabled="validating || metadataErrors || badFileName"
            variant="primary"
            >Validate</b-button
          >

          <div class="ml-3">
            <app-loading :loading="uploading === true" />
          </div>
        </div>
      </template>
    </template>
  </div>
</template>

<script>
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';
import Papa from 'papaparse';
import vueFilePond from 'vue-filepond';
import 'filepond/dist/filepond.min.css';

import ApiClient from '@/ApiClient.js';
import AppLoading from '@/components/AppLoading.vue';
import SmeAlert from '@/components/atoms/SmeAlert.vue';
import IntegrationsState from '@/state/IntegrationsState';
import State from '@/state/State';

const FilePond = vueFilePond(FilePondPluginFileValidateType);
const textDecoder = new TextDecoder();
const defaultUpload = {
  fileContent: undefined,
  fileParsed: undefined,
  fileName: undefined,
  validationErrors: {},
};

export default {
  name: 'SftpBulkUpload',
  components: { AppLoading, FilePond, SmeAlert },
  props: {
    entity: String,
    payScheduleId: String,
    title: String,
    metadata: Object,
    metadataErrors: Boolean,
    columns: Array,
    postFunction: Function,
    emitError: Boolean,
  },
  data() {
    return {
      error: undefined,
      upload: { ...defaultUpload },
      uploading: false,
      successfullyValidated: false,
      validating: false,
      badFileName: false,
      state: State.state,
      integrationsState: IntegrationsState,
    };
  },
  methods: {
    onAddFile(error) {
      if (error) {
        this.upload.fileContent = undefined;
        this.upload.fileName = undefined;
        return;
      }
      const fileReader = new FileReader();
      const file = this.$refs.file.getFiles()[0].file;
      this.upload.fileName = file.name;
      fileReader.onloadend = this.handleFileRead;
      fileReader.readAsArrayBuffer(file);
    },
    handleFileRead(e) {
      this.upload.fileContent = new Uint8Array(e.target.result);

      if (this.bypassValidation) {
        return;
      }

      if (this.requiredFileNames.length > 0 && !this.requiredFileNames.includes(this.upload.fileName)) {
        this.badFileName = true;
        this.upload.validationErrors['allowedFileNames'] = this.requiredFileNames;
        return;
      }

      // Note: We convert file to string for validation only - it is sent as byte array => base64
      // If file is not in utf-8 encoding, we expect API to handle this. fileAsString may contain missing characters
      // if file is not utf-8 but this should not be an issue to validate column headings
      // remove BOM marker from file content
      const fileAsString = textDecoder.decode(this.upload.fileContent).replace(/^\uFEFF/, '');

      const that = this;
      Papa.parse(fileAsString, {
        header: true,
        complete(results) {
          that.upload.fileParsed = results;
          that.updateValidationErrors();
        },
        error(errors) {
          that.upload.fileParsed = errors;
        },
      });
    },
    onRemoveFile() {
      this.upload.fileContent = undefined;
      this.upload.validationErrors = {};
      this.upload.fileName = undefined;
      this.badFileName = false;
      this.successfullyValidated = false;
      this.$emit('fileRemoved');
    },
    updateValidationErrors() {
      if (!this.upload.fileContent || !this.upload.fileParsed) {
        this.upload.validationErrors = null;
      }

      let csvColumns = this.upload.fileParsed.meta.fields;

      function arrayDiff(arr1, arr2) {
        return arr1
          .filter(x => !arr2.includes(x))
          .map(x => '"' + x + '"')
          .join(', ');
      }

      // If this is an AIRFLOW BU integration, we can allow spaces to be replaced with underscores
      if (this.integrationsState.getIsAirflowPlatformEnabled()) {
        csvColumns = csvColumns.map(x => x.replaceAll(' ', '_'));
      }

      const errors = {
        missing: arrayDiff(this.requiredColumns, csvColumns),
        additional: arrayDiff(csvColumns, this.requiredColumns),
      };

      try {
        let decoded_content = textDecoder.decode(this.upload.fileContent);
        if (this.state.company.properties?.portal?.skip_unicode_apostrophe_characters_in_uploads) {
          decoded_content = decoded_content.replaceAll('’', "'");
        }
        btoa(decoded_content);
      } catch (error) {
        errors.invalidCharacters = 'The file has invalid characters, please fix them before uploading the file';
      }

      if (errors.missing || errors.additional || errors.invalidCharacters) {
        this.upload.validationErrors = errors;
      } else {
        this.upload.validationErrors = {};
      }
    },
    validateCsv() {
      this.uploading = true;
      this.validating = true;
      const fileName = this.keepOriginalFileName ? this.upload.fileName : this.entity + '.csv';

      ApiClient.validateFile(
        this.state.company.company_id,
        fileName,
        this.upload.fileContent,
        this.entity.toLowerCase(),
      )
        .then(response => {
          this.uploading = false;
          this.validating = false;
          if (response.data.ok) {
            this.successfullyValidated = true;
          } else {
            this.upload.validationErrors['validationReport'] = response.data.readable_validation_report;
            this.successfullyValidated = false;
          }
        })
        .catch(error => {
          this.uploading = false;
          this.validating = false;
          this.successfullyValidated = false;
          this.error = error;
        });
    },
    uploadCsv() {
      this.uploading = true;

      const fileName = this.keepOriginalFileName ? this.upload.fileName : this.entity + '.csv';
      const postFunc = this.postFunction || ApiClient.postFile;
      postFunc(this.state.company.company_id, fileName, this.upload.fileContent, this.metadata).then(
        () => {
          this.upload = { ...defaultUpload };
          this.$refs.file.removeFiles();
          this.uploading = false;
          this.validating = false;
          this.successfullyValidated = false;
          const entity = this.entity ? this.entity.charAt(0).toUpperCase() + this.entity.slice(1) : '';
          this.$appToast(`${entity} file has been uploaded`, {
            title: 'Successfully uploaded file',
            variant: 'success',
          });
          this.error = undefined;
        },
        error => {
          if (this.emitError) {
            this.$emit('error', error);
          } else {
            this.error = error.message;
          }
          this.uploading = false;
        },
      );
    },
  },
  computed: {
    bypassValidation() {
      return this.state.company.properties.portal !== undefined
        ? this.state.company.properties.portal.bypass_file_upload_validation
        : false;
    },
    enableFileValidation() {
      if (this.state.company.properties.portal !== undefined) {
        const enableEntityFileValidation = this.state.company.properties.portal.enable_entity_file_validation || [];
        return enableEntityFileValidation.includes(this.entity.toUpperCase());
      } else {
        return false;
      }
    },
    displayColumns() {
      return this.requiredColumns.map(c => '"' + c + '"').join(', ');
    },
    rowsNumber() {
      if (!this.upload.fileContent || !this.upload.fileParsed) {
        return 0;
      }
      return this.upload.fileParsed.data.length;
    },
    emptyFileLabel() {
      return '<i class="fal fa-cloud-upload"></i> ' + `Drop ${this.title} csv file here or <u>browse</u> your files...`;
    },
    requiredColumns() {
      if (this.entity === undefined) {
        return null;
      }
      if (this.columns) {
        return this.columns;
      }
      const requiredColumns = this.integrationsState.getRequiredColumns(this.entity);
      if (requiredColumns === undefined) {
        this.$emit('error', `No columns defined for ${this.entity}`);
      }
      return requiredColumns;
    },
    acceptedFileTypes() {
      return this.bypassValidation ? undefined : ['text/csv', 'text/plain', 'application/vnd.ms-excel'];
    },
    keepOriginalFileName() {
      if (this.state.company.properties.portal !== undefined) {
        const keepOriginalFileNameOnUpload =
          this.state.company.properties.portal.keep_original_file_name_on_upload || [];
        return keepOriginalFileNameOnUpload.includes(this.entity.toUpperCase());
      } else {
        return false;
      }
    },
    requiredFileNames() {
      if (this.state.company.properties.portal !== undefined) {
        const requireSpecificFileNamesOnUpload =
          this.state.company.properties.portal.require_specific_file_names_on_upload || [];
        return requireSpecificFileNamesOnUpload
          .filter(a => a.entity === this.entity.toUpperCase())
          .flatMap(element => element.files_list);
      } else {
        return [];
      }
    },
  },
};
</script>

<style lang="scss">
.filepond--drop-label {
  border: 2px dashed var(--color-primary);
  border-radius: 0.5em;
  box-sizing: border-box;
  cursor: pointer;
  label {
    cursor: pointer;
  }
}

.filepond--wrapper {
  margin-bottom: 2rem !important;
  margin-top: 2rem !important;
}
</style>

<template>
  <div class="container">
    <div class="box">
      <img :src="themeImage('raise-logo-without-icon.svg')" />
      <span>Find and hire talent for full time jobs and last minute shifts</span>
      <b-spinner v-if="loading" variant="primary" />
      <b-button v-else variant="primary" v-b-modal="'help-modal'" :loading="loading"
        >Get started&nbsp;&nbsp;<ArrowRight height="16" width="16" :color="PaletteColors['base-white']"
      /></b-button>
    </div>
  </div>
</template>

<script setup>
import ArrowRight from '@/assets/icons/ArrowRight.vue';
import { PaletteColors, themeImage } from '@/Theme';
import { ref } from 'vue';

const loading = ref(false);
</script>

<style scoped lang="scss">
.container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.75rem;
  min-height: 10rem;
}
.box {
  width: 100%;
  height: 100%;
  color: var(--palette-color-default);
  background-color: var(--palette-color-core-1-lighten-60);
  border: 2px solid var(--palette-color-base-white);
  border-radius: 0.5rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 1rem;
}
</style>

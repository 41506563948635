import { toCurrency } from '@/utils';
import { toFormattedDate } from '@/utils/date';

export const PER_PAGE_OPTIONS = [25, 50, 100];

export const SHIFT_FIELD_DEFINITIONS = [
  {
    key: 'employee_code',
    label: 'Employee Code',
  },
  {
    key: 'full_name',
    label: 'Employee',
    sortable: false,
  },
  {
    key: 'worked_on',
    label: 'Date',
    formatter: value => {
      if (value) {
        return toFormattedDate(value);
      }
    },
  },
  {
    key: 'hours',
    label: 'Hours',
    formatter: value => value.toLocaleString('en-GB', { maximumFractionDigits: 2 }),
  },
  {
    key: 'wages',
    label: 'Earned Wages',
    formatter: (value, _key, item) => toCurrency(value, item.currency),
  },
  {
    key: 'wages_per_hour',
    label: 'Rate',
    formatter: (_value, _key, item) =>
      toCurrency(item.hours !== 0 ? parseFloat(item.wages) / parseFloat(item.hours) : 0, item.currency),
  },
  {
    export: false,
    key: 'remove_shift',
    label: '',
    tdClass: 'text-right',
    thStyle: { width: '10%' },
  },
];

export const NET_PAY_SHIFT_FIELD_DEFINITIONS = [
  {
    key: 'employee_code',
    label: 'Employee Code',
  },
  {
    key: 'full_name',
    label: 'Employee',
    sortable: false,
  },
  {
    key: 'worked_on',
    label: 'Date',
    formatter: value => {
      if (value) {
        return toFormattedDate(value);
      }
    },
  },
  {
    key: 'wages',
    label: 'Earned Wages',
    formatter: (value, _key, item) => toCurrency(value, item.currency),
  },
  {
    key: 'net_total',
    label: 'Projected Net Pay',
    formatter: (value, _key, item) => toCurrency(value, item.currency),
  },
  {
    key: 'gross_total',
    label: 'Projected Gross Pay',
    formatter: (value, _key, item) => toCurrency(value, item.currency),
  },
  {
    key: 'shift_type',
    label: 'Type',
  },
];

export const SHIFT_FIELD_KEYS = {
  EMPLOYEE_CODE: 'employee_code',
  FULL_NAME: 'full_name',
  WORKED_ON: 'worked_on',
  HOURS: 'hours',
  WAGES: 'wages',
  WAGES_PER_HOUR: 'wages_per_hour',
  REMOVE_SHIFT: 'remove_shift',
};

export const NET_PAY_SHIFT_FIELD_KEYS = {
  EMPLOYEE_CODE: 'employee_code',
  FULL_NAME: 'full_name',
  WORKED_ON: 'worked_on',
  WAGES: 'wages',
  NET_TOTAL: 'net_total',
  GROSS_TOTAL: 'gross_total',
  SHIFT_TYPE: 'shift_type',
};

export const SHIFT_FILTER_KEYS = {
  START_DATE: 'startDate',
  END_DATE: 'endDate',
  PAY_SCHEDULE: 'pay_schedule',
  PAY_SCHEDULE_DATE: 'pay_schedule_date',
  SALARY_OR_HOURLY: 'salary_or_hourly',
  SOURCE: 'source',
  DIVISIONS: 'divisions',
};

<template>
  <div
    class="sme-file-input"
    @dragover.prevent="fileDragged = true"
    @dragleave.prevent="fileDragged = false"
    @drop.prevent="handleFileDrop"
  >
    <label class="sme-file-input__label" :class="{ 'sme-file-input--dragged': fileDragged }" :for="id">
      <input :id="id" type="file" :accept="acceptedFileTypes.join(', ')" @change="handleFileUpload" />

      <template v-if="file">
        {{ file.name }}
      </template>
      <template v-else>
        <font-awesome-icon :icon="['fal', 'cloud-upload']" class="sme-file-input__icon" />
        <span>
          <!-- eslint-disable vue/no-v-html -->
          <span class="d-block" v-html="uploadText"></span>
          <!-- eslint-enable vue/no-v-html -->
          <small>
            (supported file types: {{ acceptedFileTypes.map(fileType => FILE_TYPE_EXTENSIONS[fileType]).join(', ') }})
          </small>
        </span>
      </template>
    </label>
  </div>
</template>

<script>
export const FILE_TYPES = {
  CSV: 'text/csv',
  XLS: 'application/vnd.ms-excel',
  XLSX: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
};

export const FILE_TYPE_EXTENSIONS = {
  [FILE_TYPES.CSV]: 'csv',
  [FILE_TYPES.XLS]: 'xls',
  [FILE_TYPES.XLSX]: 'xlsx',
};
</script>

<script setup>
import { ref } from 'vue';

defineProps({
  file: File,
  acceptedFileTypes: {
    type: Array,
    validator: acceptedFileTypes => acceptedFileTypes.every(fileType => Object.values(FILE_TYPES).includes(fileType)),
  },
  id: {
    type: String,
    required: true,
  },
  uploadText: {
    type: String,
    default: 'Drop file here or <u>click</u> to browse',
  },
});

const emit = defineEmits(['change']);

const fileDragged = ref(false);

const handleFileDrop = event => {
  fileDragged.value = false;

  handleFileUpload(event);
};

const handleFileUpload = event => emit('change', event);
</script>

<style lang="scss" scoped>
.sme-file-input__label {
  align-items: center;
  background-color: var(--palette-color-default-lighten-95);
  border: 1px dashed var(--palette-color-default-lighten-80);
  border-radius: 8px;
  cursor: pointer;
  font-size: 16px;
  display: flex;
  justify-content: center;
  margin: 0;
  padding: 1.5rem;
  transition: background-color 0.25s;

  input[type='file'] {
    display: none;
  }
}

.sme-file-input--dragged {
  background-color: var(--palette-color-base-white);

  .sme-file-input__icon {
    color: var(--palette-color-brand-primary);
  }
}

.sme-file-input__icon {
  height: 2rem;
  margin-right: 1rem;
  transition: color -0.25s;
  width: 2rem;
}
</style>

<template>
  <app-page
    title="Verify Ownership"
    subtitle="Finish KYC (Know Your Customer) check to verify company ownership"
    :error="error"
    icon="user"
  >
    <template v-slot:infobox>
      <sme-info-box title="About the ID check" id="about-id-check" save-state>
        <p>We need you to verify the identity of company owners/directors to satisfy banking regulations.</p>
        <p>
          Verification is provided by <strong>Stripe</strong> and can be completed on desktop or mobile in just a few
          minutes.
        </p>
        <p>Invite owners/directors to verify their identity. They will need:</p>
        <ul>
          <li>Proof of identity, such as a driving license or passport</li>
          <li>A way to take a photo of themselves, such as a webcam or a mobile phone camera</li>
        </ul>
        <p>These instructions will also be provided in the invitation email we send.</p>
      </sme-info-box>
    </template>

    <template v-slot:custom-content>
      <!-- Loading -->
      <template v-if="loading">
        <app-loading />
      </template>

      <!-- Loaded -->
      <template v-else>
        <b-container fluid>
          <!-- Error alert -->
          <sme-alert v-if="error" level="danger" class="mb-3">{{ error }}</sme-alert>
          <!-- Failed alert -->
          <!-- eslint-disable vue/no-v-html vue/no-v-text-v-html-on-component -->
          <sme-alert v-if="isFailed && !completed" level="warning" class="mb-3">
            Verification failed for one of the subjects. Please contact our sales team via chat or
            <a href="mailto:sme@wagestream.com">sme@wagestream.com</a>
            if you have any verification issues.
          </sme-alert>
          <!-- eslint-enable vue/no-v-html vue/no-v-text-v-html-on-component -->

          <!-- Owner content -->
          <sub-page :title="headerText" large>
            <b-list-group class="owner-list" flush>
              <!--
                  Loop thru array of owners (subjectsToCheck)
                -->
              <b-list-group-item
                class="owner-list__item flex-sm-row align-items-sm-center"
                v-for="(subject, index) in subjectsToCheck"
                :key="`${subject.relevant_person_id}-${index}`"
              >
                <!-- Owner name and status -->
                <div
                  class="owner-list__item__content owner-list__item__content--details d-flex flex-column flex-sm-row"
                >
                  <div class="owner-list__item__meta">
                    <figure>
                      <img src="/img/user-avatar.png" class="owner-list__item__avatar mr-2" />
                    </figure>
                    <div class="owner-list__item__meta__name">
                      <h4 class="mb-0">{{ subject.first_name }} {{ subject.middle_name }} {{ subject.last_name }}</h4>
                      <b-badge :variant="getStatusColor(subject.kyc_status)">
                        {{
                          verificationStates[
                            subject.kyc_status === ' ' || subject.kyc_status === '' ? 'null' : subject.kyc_status
                          ]
                        }}
                      </b-badge>
                    </div>
                  </div>
                </div>

                <!-- Resend email form -->
                <div
                  v-if="showResendEmailLink(subject)"
                  class="owner-list__item__content owner-list__item__content--send my-4 my-sm-0"
                >
                  <b-form-group class="d-flex justify-content-sm-end">
                    <b-input-group>
                      <b-form-input
                        :class="{ 'has-error': emailInputs[index] && !isEmailValid(emailInputs[index]) }"
                        :placeholder="`Email address for ${subject.first_name}`"
                        v-model="emailInputs[index]"
                      ></b-form-input>
                      <template #append>
                        <button
                          class="btn btn-primary"
                          :disabled="!isEmailValid(emailInputs[index])"
                          @click="onInvite(subject.relevant_person_id, emailInputs[index], index)"
                        >
                          {{ emailSendLoading[index] ? 'Sending email...' : 'Resend link' }}
                        </button>
                      </template>
                    </b-input-group>
                  </b-form-group>
                </div>

                <!-- Send email form -->
                <div
                  v-if="showEmailLink(subject)"
                  class="owner-list__item__content owner-list__item__content--send my-4 my-sm-0"
                >
                  <b-form-group class="d-flex justify-content-sm-end">
                    <b-input-group>
                      <b-form-input
                        :class="{ 'has-error': emailInputs[index] && !isEmailValid(emailInputs[index]) }"
                        :placeholder="`Email address for ${subject.first_name}`"
                        v-model="emailInputs[index]"
                      ></b-form-input>
                      <template #append>
                        <button
                          class="btn btn-primary"
                          :disabled="!isEmailValid(emailInputs[index])"
                          @click="onInvite(subject.relevant_person_id, emailInputs[index], index)"
                        >
                          {{ emailSendLoading[index] ? 'Sending email...' : 'Send link' }}
                        </button>
                      </template>
                    </b-input-group>
                  </b-form-group>
                </div>

                <!-- This is me -->
                <div v-if="showThisIsMeButton(subject)" class="owner-list__item__content">
                  <div class="d-flex d-sm-block d-md-flex flex-align-center">
                    <b-btn variant="outline-primary" block @click="createRelevantPersonConnection(subject)">
                      This is me!
                    </b-btn>
                  </div>
                </div>

                <!-- Try again -->
                <div v-if="showTryAgainButton(subject)" class="owner-list__item__content">
                  <b-btn block variant="outline-primary" @click="handleVerification(subject.user_id)">Try again</b-btn>
                </div>
              </b-list-group-item>
            </b-list-group>
          </sub-page>
        </b-container>
      </template>
    </template>
  </app-page>
</template>

<script>
import ApiClient from '@/ApiClient';
import tracker from '@/Tracker';
import AppLoading from '@/components/AppLoading';
import AppPage from '@/components/AppPage';
import SubPage from '@/components/SubPage.vue';
import SmeAlert from '@/components/atoms/SmeAlert.vue';
import SmeInfoBox from '@/components/atoms/SmeInfoBox.vue';
import State from '@/state/State.js';

const VERIFICATION_STATES = {
  null: 'No verification started',
  NEW: 'Verification in progress',
  CANCELLED: 'Verification Cancelled',
  SUCCESS: 'Verification Successful',
  FAILURE: 'Verification Failed',
  REVIEW: 'Verification requires manual review',
};

export default {
  name: 'VerifyOwnership',
  components: {
    AppLoading,
    AppPage,
    SubPage,
    SmeAlert,
    SmeInfoBox,
  },
  data() {
    return {
      completed: false,
      loading: false,
      error: undefined,
      subjectsToCheck: [],
      isOwners: undefined,
      emailInputs: [],
      personsInvited: [],
      emailSendLoading: {},
      verificationStates: VERIFICATION_STATES,
    };
  },
  async mounted() {
    try {
      this.loading = true;

      const tasks = await ApiClient.smeCompanyGetTaskList(State.state.company.company_id);
      this.tasks = tasks.tasks;
      this.completed = tasks.tasks.some(elem => elem.code === 'VERIFY_IDENTITY' && elem.status === 'COMPLETE');

      const serverResponse = await ApiClient.smeCompanyGetRelevantPeople(State.state.company.company_id);
      this.subjectsToCheck = serverResponse.relevant_people.sort((a, b) => a.last_name > b.last_name);
      this.emailSendLoading = this.subjectsToCheck.reduce((res, item, index) => {
        res[index] = false;
        return res;
      }, {});

      this.isOwners = serverResponse.is_owners;
      this.personsInvited = this.subjectsToCheck
        .filter(elem => elem.user_id != null)
        .map(elem => elem.relevant_person_id);
    } catch (error) {
      this.error = error;
    } finally {
      this.loading = false;
    }
  },
  computed: {
    isFailed() {
      return this.subjectsToCheck.find(s => s.kyc_status === 'FAILURE') !== undefined;
    },
    headerText() {
      return this.isOwners
        ? `We need to verify the identity of all of the following owners`
        : `We need to verify the identity of just one of the following directors`;
    },
  },
  methods: {
    getStatusColor(status) {
      switch (status) {
        case 'SUCCESS':
          return 'success';
        case 'FAILURE':
          return 'danger';
        case 'CANCELLED':
          return 'danger';
        case 'NEW':
          return 'warning';
        case 'REVIEW':
          return 'warning';
        default:
          return '';
      }
    },
    showEmailLink(subject) {
      return (
        !this.completed &&
        subject.user_id !== State.state.user.user_id &&
        subject.kyc_status !== 'SUCCESS' &&
        subject.kyc_status !== 'NEW'
      );
    },
    showResendEmailLink(subject) {
      return !this.completed && subject.user_id !== State.state.user.user_id && subject.kyc_status === 'NEW';
    },

    emailSent(subject) {
      return this.personsInvited.includes(subject.relevant_person_id) && subject.user_id !== State.state.user.user_id;
    },

    isEmailValid(email) {
      const emailRe = /^.+@.+\..+$/;
      return emailRe.test(email);
    },

    showThisIsMeButton(subject) {
      const userId = State.state.user.user_id;
      const alreadyMapped = this.subjectsToCheck.some(elem => {
        return elem.user_id === userId; // && elem.relevant_person_id !== subject.relevant_person_id;
      });
      return !this.completed && !alreadyMapped && subject.kyc_status === null;
    },

    showTryAgainButton(subject) {
      return subject.user_id === State.state.user.user_id && subject.kyc_status !== 'SUCCESS';
    },

    async createRelevantPersonConnection(subject) {
      const userId = State.state.user.user_id;
      this.loading = true;

      if (subject.user_id == null) {
        await tracker.trackEvent('sme_activation_kyc_self');
        // Create a link between userId and relevantPersonId:
        try {
          await ApiClient.smeCompanyCreateRelevantPersonConnection(userId, {
            company_id: State.state.company.company_id,
            relevant_person_id: subject.relevant_person_id,
          });
        } catch (error) {
          // Ignore unprocessable entity:
          if (error.response.status !== 422) {
            this.error = error.message || error.toString();
            this.loading = false;
          }
        }
      }
      // this.$router.push({ name: 'sme-kyc', query: { id: userId, dashboard: true } });
      this.handleVerification(userId);
    },

    async onInvite(relevantPersonId, email, index) {
      await tracker.trackEvent('sme_activation_kyc_invite');
      this.emailSendLoading[index] = true;

      try {
        const relevantInviteResult = await ApiClient.smeInviteRelevantPerson(
          relevantPersonId,
          email,
          State.state.company.company_id,
        );
        if (relevantInviteResult.ok) {
          this.$appToast(`Invite email successfully sent to address: ${email}`, {
            title: 'Success. Invite email sent.',
            variant: 'success',
          });
        }
      } catch (error) {
        this.$appToast(`Error message: ${error.message ? error.message : error}`, {
          title: 'Error while trying to send email invite.',
          variant: 'warning',
        });
      } finally {
        this.emailSendLoading[index] = false;
      }

      this.personsInvited.push(relevantPersonId);
    },

    async handleVerification(userId) {
      try {
        const person = await ApiClient.getKYCStatus(userId);
        const payload = {
          company_id: person.company_id,
          return_url: window.location.href,
        };
        const { redirect_url } = await ApiClient.smeCompanyVerifyRelevantPersonConnection(userId, payload);
        window.location.assign(redirect_url);
      } catch (error) {
        this.error = error.message || error.toString();
        this.loading = false;
      }
    },
  },
};
</script>

<style scoped lang="scss">
.owner-list__item {
  display: flex;
  flex-direction: column;
}

.owner-list__item__content {
  margin: 0.5rem 0;
  &:first-child {
    margin-top: 0;
  }
  &:last-child {
    margin-bottom: 0;
  }
  @media screen and (min-width: 576px) {
    margin: 0 0.5rem;

    &:first-child {
      margin-left: 0;
    }
    &:last-child {
      margin-right: 0;
    }
  }
}

.owner-list__item__meta {
  display: flex;
  align-items: center;
}
.owner-list__item__content--details {
  @media screen and (min-width: 576px) {
    > div {
      min-width: 300px;
    }
  }
}

.owner-list__item__avatar {
  width: 50px;
}
</style>

<template>
  <section>
    <AppLoading v-if="filteredAssignments.length === 0" />
    <template v-else>
      <p class="mb-3">This employee has {{ filteredAssignments.length }} other records at your company:</p>
      <b-table primary-key="name" :items="filteredAssignments" :fields="FIELDS" table-class="table-minimal" show-empty>
        <template #cell(profile)="data">
          <b-button
            class="border-0"
            size="sm"
            @click="() => redirectToAssignmentProfile(data.item.employee_id)"
            :variant="data.item.type === 'Contract' ? 'outline' : 'outline-primary'"
            :disabled="data.item.type === 'Contract'"
          >
            <font-awesome-icon :icon="['fad', 'user']" />
          </b-button>
        </template>
        <template #cell(full_name)="data">
          {{ data.item.full_name }}
        </template>
        <template #cell(employee_code)="data">
          {{ data.item.employee_code }}
        </template>
        <template #cell(payschedule)="data">
          {{ data.item.payschedule }}
        </template>
        <template #cell(contract)="data">
          {{ data.item.contract }}
        </template>
        <template #cell(current_state)="data">
          {{ convertBackendStatusToDisplayStatus(data.item) }}
        </template>
      </b-table>
    </template>
  </section>
</template>

<script setup>
import { computed } from 'vue';
import { useRouter } from 'vue-router/composables';
import AppLoading from '@/components/AppLoading.vue';
import { convertBackendStatusToDisplayStatus } from '@/utils/Employee';

const $router = useRouter();

const FIELDS = [
  {
    key: 'profile',
    label: 'Profile',
  },
  {
    key: 'full_name',
    label: 'Name',
  },
  {
    key: 'employee_code',
    label: 'Employee code',
  },
  {
    key: 'payschedule',
    label: 'Pay Schedule',
  },
  {
    key: 'type',
    label: 'Type',
  },
  {
    key: 'contract',
    label: 'Contract',
  },
  {
    key: 'current_state',
    label: 'Status',
  },
];

const props = defineProps({
  employee: {
    type: Object,
    required: true,
  },
  payschedules: {
    type: Array,
    required: true,
  },
  otherAssignments: {
    type: Array,
    required: true,
  },
});

const filteredAssignments = computed(() => {
  const assignments = [];
  for (const assignment of props.otherAssignments) {
    const payschedule =
      props.payschedules.find(paySchedule => paySchedule.pay_schedule_id === assignment.pay_schedule_id)?.name || 'N/A';
    let type = 'Assignment';
    let fullName = assignment.full_name;
    let employeeCode = assignment.employee_code;
    let currentState = assignment.current_state;
    const contract = assignment.properties?.contract_type || 'N/A';
    if (assignment.employee_contract_id) {
      type = 'Contract';
      fullName = props.employee.full_name;
      employeeCode = props.employee.employee_code;
      if (assignment.properties.inactive) {
        currentState = 'DISABLED';
      } else {
        currentState = props.employee.current_state;
      }
    }
    assignments.push({
      type: type,
      employee_contract_id: assignment.employee_contract_id,
      employee_id: assignment.employee_id,
      payschedule: payschedule,
      full_name: fullName,
      employee_code: employeeCode,
      current_state: currentState,
      contract: contract,
    });
  }
  return assignments;
});

const redirectToAssignmentProfile = employeeId => {
  $router.push({
    name: 'employee',
    params: {
      employeeId: employeeId,
    },
  });
};
</script>

<template>
  <app-page>
    <template v-slot:custom-content>
      <b-container class="pb-4">
        <dashboard-terms-modal />
        <!-- todo swap these out for feature flags -->
        <terms-amendment-modal
          v-if="State?.state?.company?.properties?.[DB_SALARY_SERVICE_ADDENDUM.companyProperty]"
          :termsAmendment="DB_SALARY_SERVICE_ADDENDUM"
        />
        <!-- todo swap these out for feature flags -->
        <terms-amendment-modal
          v-if="State?.state?.company?.properties?.[DB_TERMS_OF_BUSINESS_AMENDMENT.companyProperty]"
          :termsAmendment="DB_TERMS_OF_BUSINESS_AMENDMENT"
        />
        <!-- todo swap these out for feature flags -->
        <terms-amendment-modal
          v-if="State?.state?.company?.properties?.[HEALTHCARE_TERMS_OF_BUSINESS_AMENDMENT.companyProperty]"
          :termsAmendment="HEALTHCARE_TERMS_OF_BUSINESS_AMENDMENT"
        />
        <sme-tour v-if="isTourApplicable" :tour="dashboardTour" class="mt-n4 mb-3" id="dashboard-tour" auto-start />
        <DashboardRaisePromo v-if="isEnableTalentPool" />
        <dashboard-header-stats class="mb-2" />
        <b-row>
          <b-col lg="6" class="mb-4 mb-lg-0">
            <dashboard-todos v-if="State.state.claims.a" data-id="dashboard.todos" />
            <dashboard-employees data-id="dashboard.employees" />
          </b-col>
          <b-col lg="6">
            <dashboard-payschedules v-if="State.state.claims.a" data-id="dashboard.payschedules" />
            <dashboard-shifts data-id="dashboard.shifts" />
          </b-col>
        </b-row>
        <dashboard-adoption v-if="isActivating && !isSelfFunded && isEnableAdoptionGamification" class="mt-4 p-0" />
      </b-container>
    </template>
  </app-page>
</template>

<script setup>
import { computed } from 'vue';
import AppPage from '@/components/AppPage.vue';
import SmeTour from '@/components/Tour.vue';
import useFeatureFlages from '@/composables/useFeatureFlags';
import DashboardAdoption from '@/pages/dashboard/components/DashboardAdoption.vue';
import DashboardHeaderStats from '@/pages/dashboard/components/DashboardHeaderStats.vue';
import TermsAmendmentModal from '@/pages/dashboard/components/TermsAmendmentModal.vue';
import DashboardTermsModal from '@/pages/dashboard/components/DashboardTermsModal.vue';
import DashboardEmployees from '@/pages/dashboard/components/employees/DashboardEmployees.vue';
import DashboardPayschedules from '@/pages/dashboard/components/payschedules/DashboardPayschedules.vue';
import DashboardShifts from '@/pages/dashboard/components/shifts/DashboardShifts.vue';
import DashboardTodos from '@/pages/dashboard/components/todos/DashboardTodos.vue';
import dashboardTour from '@/pages/dashboard/dashboardTour';
import DashboardRaisePromo from '@/pages/dashboard/components/raisePromo/DashboardRaisePromo.vue';
import State from '@/state/State';
import useEmployees from '@/state/composables/useEmployees';
import useLaunchState from '@/state/composables/useLaunchState';
import { TODO_ACTIONS } from '@/utils/Todo';
import {
  DB_SALARY_SERVICE_ADDENDUM,
  DB_TERMS_OF_BUSINESS_AMENDMENT,
  HEALTHCARE_TERMS_OF_BUSINESS_AMENDMENT,
} from '@/utils/agreements';

const { isSelfFunded, isEnableAdoptionGamification, isEnableTalentPool } = useFeatureFlages();

const TOUR_TODO_ACTIONS = [
  TODO_ACTIONS.CONFIGURE_PAYSCHEDULE,
  TODO_ACTIONS.ADD_EMPLOYEES,
  TODO_ACTIONS.KYC_PENDING,
  TODO_ACTIONS.FUND_PENDING,
];

const { getEmployees, getEnrolmentSnapshot } = useEmployees();
const { getLaunchState, hasTodoAction, isActivating, outstandingTodos } = useLaunchState();

const isTourApplicable = computed(
  () =>
    TOUR_TODO_ACTIONS.some(hasTodoAction) &&
    // ensure that tour does not display over terms modal
    !(outstandingTodos.value ? outstandingTodos?.value.find(t => t.action === 'accept.terms') !== undefined : true),
);

Promise.all([getEmployees(), getEnrolmentSnapshot(), getLaunchState()]);
</script>

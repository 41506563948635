<template>
  <div v-if="!error" class="container">
    <app-loading />
    <sme-alert v-if="loading" level="info">
      Synchronizing your data from Xero. This could take up to a few minutes, depending on the size of your
      organisation.
    </sme-alert>
  </div>
  <div v-else-if="error" class="container">
    <sme-alert level="danger">{{ error }}</sme-alert>
  </div>
</template>

<script>
import ApiClient from '@/ApiClient';
import AppLoading from '@/components/AppLoading.vue';
import SmeAlert from '@/components/atoms/SmeAlert.vue';
import OnboardingState from '@/state/OnboardingState.js';

export default {
  name: 'XeroRegistration',
  components: {
    AppLoading,
    SmeAlert,
  },
  data() {
    return {
      state: OnboardingState.load(),
      error: undefined,
      loading: false,
    };
  },
  async mounted() {
    const code = this.$route.query.code;
    if (code) {
      const state = this.$route.query.state;
      try {
        this.loading = true;
        const resp = await ApiClient.postXeroRegistration({
          code,
          state,
        });
        this.loading = false;
        if (resp.auth) {
          this.state.state.companyId = resp.data.companyId;
          this.state.state.userId = resp.data.userId;
          this.state.save();
          await ApiClient.getUser();
        }
        this.$router.push({ name: resp.data.redirect, params: resp.data.params || {} });
      } catch (e) {
        const message = e.response.data.message;
        if (!message || message.toLowerCase() === 'internal server error') {
          this.error =
            "Unfortunately your sign up has not been successful. Please contact us at sme@wagestream.com and we'll get you signed up in no time.";
        } else {
          this.error = message;
        }
      }
    } else {
      const callback_url = `${window.location.origin}/utils/redirectXero.html`;
      const state = JSON.stringify({
        callback_url: callback_url,
        purpose: 'registration',
      });
      const url = await ApiClient.getXeroAuthUrl(callback_url, state);

      window.location.replace(url.url);
    }
  },
};
</script>

<style scoped>
.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
}

.sme-alert {
  max-width: 30rem;
  width: 100%;
}
</style>

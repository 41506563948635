<template>
  <div>
    <AppRadioCards
      :value="planName"
      @input="emit('input:planName', $event)"
      :options="plans"
      class="register-subscription__cards"
    />
    <SmeCard v-if="planName" class="register-subscription__payment">
      <h1 class="register-subscription__payment-header">Pay now with Stripe</h1>
      <div ref="stripeCardDom"></div>
      <input
        :value="promoCode"
        @input="emit('input:promoCode', $event)"
        type="text"
        placeholder="Promo Code (Optional)..."
        class="register-subscription__payment-code"
      />
    </SmeCard>
  </div>
</template>

<script setup>
import { nextTick, ref, computed, watch, onBeforeMount } from 'vue';
import AppRadioCards from '@/components/AppRadioCards.vue';
import SmeCard from '@/components/atoms/SmeCard.vue';

const emit = defineEmits(['input:planName', 'input:promoCode', 'change:card']);
const props = defineProps(['planName', 'promoCode', 'plans', 'stripe']);

const stripeCardDom = ref(null);
const stripeCard = ref(null);

const planName = computed(() => props.planName);

watch(planName, () => {
  nextTick(() => {
    if (planName.value && stripeCard.value) {
      stripeCard.value.mount(stripeCardDom.value);
    }
    stripeCard.value.addEventListener('change', event => {
      if (event.complete) {
        emit('change:card', stripeCard.value);
      } else {
        // if the card number is not valid, do not emit a value
        emit('change:card', null);
      }
    });
  });
});

onBeforeMount(() => {
  stripeCard.value = props.stripe.elements().create('card', { hidePostalCode: true, disableLink: true });
});
</script>

<style scoped lang="scss">
.register-subscription__cards {
  margin-bottom: 0;
}
.register-subscription__payment {
  margin-top: 1.5rem;
}
.register-subscription__payment-header {
  margin-bottom: 1.5rem;
}
.register-subscription__payment-code {
  margin-top: 1.5rem;
  border: none;
  font-size: 14px;
}
</style>

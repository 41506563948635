<template>
  <div class="adoption-wrapper">
    <app-loading :loading="loading" />

    <sme-card v-if="!loading" large>
      <b-container fluid>
        <h2 class="adoption-header">Boost your adoption rate to unlock rewards 🌟</h2>
        <b-row class="my-3">
          <b-col sm="6" class="section adoption-metric-wrapper">
            <section class="metric-grid">
              <app-stat
                v-for="metric in adoptionMetrics"
                :key="metric.title"
                :stat="metric.value"
                :description="metric.title"
                background-color="core-1-lighten-70"
                color="brand-primary"
                small
              />
            </section>
            <div class="voucher-carousel-wrapper">
              <div class="voucher-text">
                <h4 v-if="!adoptionTargetComplete">Your next reward</h4>
                <h4 v-else>Adoption target achieved</h4>

                <p v-if="!adoptionTargetComplete">
                  {{ String(adoptionTarget) }}% adoption unlocks
                  <span class="bold-color-text">premium vouchers</span> for your employees, letting them save money at
                  113 high street retailers - from football boots to the weekly food shop.
                </p>
                <p v-else>
                  Congrats! You have unlocked <span class="bold-color-text">premium vouchers</span> for your employees,
                  letting them save money at 113 high street retailers - from football boots to the weekly food shop.
                </p>

                <p>
                  Discounts include 7% at Nike, 15% at National Express, 10% at New Look and many more to choose from...
                </p>
              </div>

              <b-carousel
                id="voucher-carousel"
                v-model="slide"
                :interval="4000"
                background="var(--palette-base-white)"
                :img-width="slideWidth"
                :img-height="slideHeight"
                @sliding-start="onSlideStart"
                @sliding-end="onSlideEnd"
              >
                <b-carousel-slide v-for="carouselSlide in voucherSlides" :key="carouselSlide.logo">
                  <template #img>
                    <img class="d-block img-fluid w-100 slide-img" :src="carouselSlide.logo" :alt="carouselSlide.alt" />
                    <p class="voucher-carousel-text">{{ carouselSlide.discount }}% off</p>
                  </template>
                </b-carousel-slide>
              </b-carousel>
            </div>
          </b-col>

          <b-col sm="6" class="section chart-wrapper">
            <AdoptionChart :company="state.company" @onload="getAdoptionMetrics" />
          </b-col>
        </b-row>

        <b-row class="justify-content-center text-center mt-1 mb-2">
          <h5>Let your employees know about us with one of these easy steps:</h5>
        </b-row>
        <b-row class="section asset-btn-wrapper">
          <div class="adoption-step">
            <a
              rel="noopener"
              :href="whatsappUrl"
              target="_blank"
              class="btn btn-primary"
              id="asset-btn"
              @click="trackAdoptionClicks('whatsapp')"
            >
              <font-awesome-icon :icon="['fas', 'comment']" class="asset-btn-icon" />
              <span>Send a WhatsApp</span>
            </a>
          </div>

          <div class="adoption-step">
            <a
              rel="noopener"
              :href="emailUrl"
              class="btn btn-primary"
              id="asset-btn"
              @click="trackAdoptionClicks('email')"
            >
              <font-awesome-icon :icon="['fas', 'envelope']" class="asset-btn-icon" />
              <span>Email your team</span>
            </a>
          </div>

          <div v-if="!isDisableLandingPageLinks" class="adoption-step">
            <a
              rel="noopener"
              href="https://dashboard.wagestream.com/learn/video/getting-started"
              target="_blank"
              class="btn btn-primary"
              id="asset-btn"
              @click="trackAdoptionClicks('video')"
            >
              <font-awesome-icon :icon="['fas', 'share-square']" class="asset-btn-icon" />
              <span>Share these videos</span>
            </a>
          </div>

          <div class="adoption-step">
            <a
              download
              rel="noopener"
              href="https://wstream.wpengine.com/wp-content/uploads/2022/11/4-Products-Poster-1.pdf"
              target="_blank"
              class="btn btn-primary"
              id="asset-btn"
              @click="trackAdoptionClicks('poster')"
            >
              <font-awesome-icon :icon="['fad', 'download']" class="asset-btn-icon" />
              <span>Print off this poster</span>
            </a>
          </div>
        </b-row>
      </b-container>
    </sme-card>
  </div>
</template>

<script>
import tracker from '@/Tracker';
import AppLoading from '@/components/AppLoading.vue';
import AppStat from '@/components/AppStat.vue';
import airbnbLogo from '@/components/assets/airbnb.jpeg';
import deliverooLogo from '@/components/assets/deliveroo.png';
import natExpressLogo from '@/components/assets/nationalexp.png';
import newLookLogo from '@/components/assets/newlook.png';
import adidasLogo from '@/components/assets/nike.png';
import nintendoLogo from '@/components/assets/nintendo.png';
import SmeCard from '@/components/atoms/SmeCard.vue';
import AdoptionChart from '@/components/charts/AdoptionChart';
import useFeatureFlags from '@/composables/useFeatureFlags';
import State from '@/state/State';

export default {
  name: 'DashboardAdoption',
  components: {
    AdoptionChart,
    AppLoading,
    AppStat,
    SmeCard,
  },
  data() {
    return {
      state: State.state,
      emailUrl: undefined,
      whatsappUrl: undefined,
      adoptionTarget: 80,
      adoptionTargetComplete: false,
      adoptionMetrics: [],
      voucherSlides: [],
      deliverooLogo,
      airbnbLogo,
      adidasLogo,
      nintendoLogo,
      natExpressLogo,
      newLookLogo,
      loading: true,
      enrollmentTarget: null,
      adoptionRate: null,
      slide: 0,
      sliding: null,
      slideWidth: 100,
      slideHeight: 80,
    };
  },
  setup() {
    const { isDisableLandingPageLinks } = useFeatureFlags();

    return { isDisableLandingPageLinks };
  },
  async beforeMount() {
    const enrollment = await State.getEnrollment();
    const adoption = Math.round(((enrollment.adopted + enrollment.pending) / enrollment.eligible) * 100);
    this.adoptionRate = isNaN(adoption) ? 0 : adoption;
    if (this.adoptionRate < this.adoptionTarget) {
      const peopleNeededForTarget = (this.adoptionTarget * enrollment.eligible) / 100;
      this.enrollmentTarget = Math.round(peopleNeededForTarget - enrollment.adopted);
    } else {
      this.enrollmentTarget = 0;
      this.adoptionTargetComplete = true;
      tracker.trackEvent('sme_adoption_target_reached');
    }
    this.getWhatsappUrl();
    this.getEmailUrl();
    this.getVoucherSlides();
    this.loading = false;
  },
  methods: {
    getVoucherSlides() {
      this.voucherSlides = [
        {
          logo: deliverooLogo,
          alt: 'deliveroo',
          discount: 5,
        },
        {
          logo: adidasLogo,
          alt: 'nike',
          discount: 7,
        },
        {
          logo: airbnbLogo,
          alt: 'airbnb',
          discount: 7,
        },
        {
          logo: nintendoLogo,
          alt: 'nintendo',
          discount: 10,
        },
        {
          logo: natExpressLogo,
          alt: 'national express',
          discount: 15,
        },
        {
          logo: newLookLogo,
          alt: 'new look',
          discount: 10,
        },
      ];
    },
    getAdoptionMetrics(points) {
      const benchmarkDifference = this.getBenchmarkDifference(points);
      this.adoptionMetrics = [
        {
          value: `${this.adoptionRate}%`,
          title: 'Current adoption',
        },
        {
          value: benchmarkDifference,
          title: 'Compared to target',
        },
        {
          value: this.enrollmentTarget,
          title: this.enrollmentTarget === 1 ? 'Person until reward' : 'People until reward',
        },
      ];
    },
    trackAdoptionClicks(assetName) {
      tracker.trackEngagement('adoption_asset_clicked', { asset: assetName });
    },
    onSlideStart() {
      this.sliding = true;
    },
    onSlideEnd() {
      this.sliding = false;
    },
    getBenchmarkDifference(points) {
      const benchmarkPoint = points[0];
      const companyPoint = points[1];
      if (benchmarkPoint.y == companyPoint.y) {
        return '-%';
      } else {
        const diff = Math.round(benchmarkPoint.y - companyPoint.y);
        if (diff < 0) {
          return `+${diff * -1}%`;
        } else {
          return `-${diff}%`;
        }
      }
    },
    getWhatsappUrl() {
      if (this.adoptionTargetComplete == true) {
        this.whatsappUrl =
          'https://api.whatsapp.com/send?text=Great%20news%2C%20you%20now%20have%20access%20to%20Wagestream%2C%20a%20financial%20benefit%20which%20gives%20you%20the%20ability%20to%3A%0A%0A%F0%9F%92%B8%20Choose%20when%20you%20get%20paid%0A%0A%F0%9F%93%88Track%20your%20earnings%20between%20payday%0A%0A%F0%9F%8E%89%20Automatically%20grow%20your%20build%20pots%20%0A%0A%F0%9F%92%B0Claim%20financial%20support%20you%E2%80%99re%20owed%0A%0A%F0%9F%9B%92%20Save%20money%20on%20shopping%0A%0ADon%E2%80%99t%20miss%20out%2C%20download%20Wagestream%20today%20%E2%9C%85%0Ahttps%3A%2F%2Fwagestream.app.link%2FNAQRvx4KOub';
      } else {
        this.whatsappUrl =
          'https://api.whatsapp.com/send?text=Great%20news%2C%20you%20now%20have%20access%20to%20Wagestream%2C%20a%20financial%20benefit%20which%20gives%20you%20the%20ability%20to%3A%0A%0A%F0%9F%92%B8%20Choose%20when%20you%20get%20paid%0A%0A%F0%9F%93%88Track%20your%20earnings%20between%20payday%0A%0A%F0%9F%8E%89%20Automatically%20grow%20your%20build%20pots%20%0A%0A%F0%9F%92%B0Claim%20financial%20support%20you%E2%80%99re%20owed%0A%0A%F0%9F%9B%92%20Save%20money%20on%20shopping%0A%0ADon%E2%80%99t%20miss%20out%2C%20download%20Wagestream%20today%20%E2%9C%85%0Ahttps%3A%2F%2Fwagestream.app.link%2FNAQRvx4KOub%0A%0AP.S.%20Only%20' +
          this.enrollmentTarget +
          '%20more%20people%20at%20your%20company%20need%20to%20sign%20up%20for%20you%20to%20unlock%20premium%20discounts%20on%20your%20shopping%0A';
      }
    },
    getEmailUrl() {
      const companyName = this.prettifyCompanyName(State.state.company.name);
      if (this.adoptionTargetComplete == true) {
        this.emailUrl =
          'mailto:?subject=Your%20new%20financial%20benefit&body=Working%20at%20' +
          companyName +
          '%20just%20got%20even%20better,%20as%20today%20we%20announce%20our%20partnership%20with%20Wagestream,%20a%20new%20employee%20financial%20benefit.%0A%0AWagestream%20is%20a%20charity-backed%20financial%20wellbeing%20app,%20which%20gives%20you%20the%20ability%20to%3A%0A%0A%F0%9F%92%B8%20Choose%20when%20you%20get%20paid%20with%20flexible%20pay%0A%F0%9F%93%88%20Budget%20like%20never%20before%20with%20visibility%20of%20your%20earnings%20%0A%F0%9F%8E%89%20Build%20your%20financial%20security%20with%20build%20pots%0A%F0%9F%92%B0%20Claim%20financial%20support%20you%E2%80%99re%20owed%20through%20a%20benefits%20calculator%0A%F0%9F%9B%92%20Cut%20your%20costs%20with%20exclusive%20supermarket%20discounts%0A%0ACrucially,%20over%2070%25%20of%20people%20who%20use%20Wagestream%20feel%20more%20in%20control%20of%20their%20money.%0A%0A%E2%9C%85%20You%20can%20download%20Wagestream%20today%20using%20the%20link%20https%3A//wagestream.app.link/Sw7eJ12JOub';
      } else {
        this.emailUrl =
          'mailto:?subject=Your%20new%20financial%20benefit&body=Working%20at%20' +
          companyName +
          '%20just%20got%20even%20better,%20as%20today%20we%20announce%20our%20partnership%20with%20Wagestream,%20a%20new%20employee%20financial%20benefit.%0A%0AWagestream%20is%20a%20charity-backed%20financial%20wellbeing%20app,%20which%20gives%20you%20the%20ability%20to%3A%0A%0A%F0%9F%92%B8%20Choose%20when%20you%20get%20paid%20with%20flexible%20pay%0A%F0%9F%93%88%20Budget%20like%20never%20before%20with%20visibility%20of%20your%20earnings%20%0A%F0%9F%8E%89%20Build%20your%20financial%20security%20with%20build%20pots%0A%F0%9F%92%B0%20Claim%20financial%20support%20you%E2%80%99re%20owed%20through%20a%20benefits%20calculator%0A%F0%9F%9B%92%20Cut%20your%20costs%20with%20exclusive%20supermarket%20discounts%0A%0ACrucially,%20over%2070%25%20of%20people%20who%20use%20Wagestream%20feel%20more%20in%20control%20of%20their%20money.%0A%0A%E2%9C%85%20You%20can%20download%20Wagestream%20today%20using%20the%20link%20https%3A//wagestream.app.link/Sw7eJ12JOub%0A%0AP.S.%20Only%20' +
          this.enrollmentTarget +
          '%20more%20people%20at%20' +
          companyName +
          '%20need%20to%20sign%20up%20for%20you%20to%20unlock%20premium%20discounts%20on%20your%20shopping%0A';
      }
    },
    prettifyCompanyName(name) {
      let lowerName = name.toLowerCase();
      if (lowerName.endsWith('ltd')) {
        lowerName = lowerName.slice(0, -4);
      } else if (lowerName.endsWith('limited')) {
        lowerName = lowerName.slice(0, -8);
      }
      return lowerName.replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase());
    },
  },
};
</script>

<style scoped lang="scss">
#voucher-carousel {
  width: 30%;
  margin-top: 1rem;

  @media (max-width: 576px) {
    padding-right: 5px;
  }
}

.voucher {
  &-carousel-text {
    font-size: 15px;
    font-weight: bold;
    text-align: center;
  }

  &-carousel-wrapper {
    display: flex;
    margin-top: 1.5rem;
    height: auto;
  }

  &-text {
    width: 70%;
    padding-right: 35px;
  }
}

.slide-img {
  max-height: 118px;
}

.adoption {
  &-header {
    color: var(--palette-color-default);
    margin-top: 30px;
    text-align: center;
  }
}

.chart-wrapper {
  padding: 0 25px;
  height: auto;
}

.asset-btn {
  &-wrapper {
    display: flex;
    justify-content: space-between;
    margin: 1rem 5rem;
    padding-bottom: 1rem;
    align-items: center;

    @media (max-width: 576px) {
      margin: 0;
      justify-content: center;
    }
  }

  &-icon {
    margin-right: 14px;
    opacity: 0.85;
  }
}

#asset-btn {
  min-width: 200px;
  position: relative;
  border-bottom: none !important;
  margin: 0 auto 20px;
  display: block;
  width: 100%;
  font-size: 14px;
}

.metric-grid {
  display: grid;
  gap: 10px;
  align-items: center;
  grid-template-columns: repeat(auto-fit, minmax(10rem, 1fr));
}

.bold-color-text {
  font-weight: bold;
  color: var(--palette-color-core-5);
}
</style>

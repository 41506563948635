<template>
  <section>
    <b-table primary-key="salary_id" :items="activityHistory" :fields="FIELDS" table-class="table-minimal" show-empty>
      <template #cell(activity)="data">
        {{ getHistoryItemLabel(data.item) }}
      </template>
      <template #cell(value)="data">
        {{ getHistoryItemSummary(data.item) }}
      </template>
    </b-table>
  </section>
</template>

<script setup>
import merge from 'lodash/merge';
import moment from 'moment';
import { computed } from 'vue';
import { toCurrency, toCompactCurrency } from '@/utils';
import { EMPLOYEE_STATE } from '@/utils/Employee';
import { toFormattedDate } from '@/utils/date';

const HISTORY_KEYS = {
  FURLOUGHED: 'FURLOUGHED',
  GRANT_ASSIGNED: 'GRANT_ASSIGNED',
  GRANT_CLAIMED: 'GRANT_CLAIMED',
  OPTED_OUT: 'OPTED_OUT',
  PAUSED: 'paused',
  STATUS_CHANGED: 'STATUS_CHANGED',
  TRANSFERS: 'TRANSFERS',
  UNFURLOUGHED: 'UNFURLOUGHED',
  PROPSCHANGE: 'PROPSCHANGE',
  SALARY_CHANGE: 'SALARY_CHANGE',
};
const HISTORY_LABELS = {
  [HISTORY_KEYS.FURLOUGHED]: 'Furloughed',
  [HISTORY_KEYS.GRANT_ASSIGNED]: 'Grant Assigned',
  [HISTORY_KEYS.GRANT_CLAIMED]: 'Grant Claimed',
  [HISTORY_KEYS.OPTED_OUT]: 'Opted Out',
  [HISTORY_KEYS.PAUSED]: item => (item.properties.new.paused ? 'Paused' : 'Unpaused'),
  [HISTORY_KEYS.STATUS_CHANGED]: 'New Status',
  [HISTORY_KEYS.TRANSFERS]: item =>
    item.properties.new.transfers_disabled ? 'Stream Wages Disabled' : 'Stream Wages Enabled',
  [HISTORY_KEYS.UNFURLOUGHED]: 'Unfurloughed',
  [HISTORY_KEYS.PROPSCHANGE]: 'Employee Details Updated',
  [HISTORY_KEYS.SALARY_CHANGE]: 'Salary Change',
};
const HISTORY_VALUES = {
  [HISTORY_KEYS.FURLOUGHED]: item =>
    `Effective From: ${toFormattedDate(item.properties.effective_from)} Effective To: ${toFormattedDate(
      item.properties.effective_to,
    )}`,
  [HISTORY_KEYS.GRANT_ASSIGNED]: item =>
    `${toCurrency(item.properties.net_amount)} ${item.properties.payment_source_name}`,
  [HISTORY_KEYS.GRANT_CLAIMED]: item =>
    `${toCurrency(item.properties.net_amount)} ${item.properties.payment_source_name}`,
  [HISTORY_KEYS.PAUSED]: item => item.properties.new.paused_reason,
  [HISTORY_KEYS.OPTED_OUT]: item => item.properties.optout_reason,
  [HISTORY_KEYS.STATUS_CHANGED]: item => item.properties.state,
  [HISTORY_KEYS.SALARY_CHANGE]: item =>
    `${toCompactCurrency(item.properties.old.salary * 100)} to ${toCompactCurrency(item.properties.new.salary * 100)}`,
};
const HISTORY_ACTIVITIES = [
  HISTORY_KEYS.FURLOUGHED,
  HISTORY_KEYS.GRANT_ASSIGNED,
  HISTORY_KEYS.GRANT_CLAIMED,
  HISTORY_KEYS.TRANSFERS,
  HISTORY_KEYS.UNFURLOUGHED,
  HISTORY_KEYS.PROPSCHANGE,
  HISTORY_KEYS.SALARY_CHANGE,
];
const HISTORY_PROPERTIES = [HISTORY_KEYS.PAUSED];

const UNASSIGNED_ACTIVITY_DEFAULT_PROPERTIES = {
  user_id: undefined,
  username: undefined,
};

const FIELDS = [
  {
    key: 'logged_at',
    label: 'Date/Time',
    sortable: true,
    formatter: value => toFormattedDate(value, 'YYYY-MM-DD HH:mm'),
  },
  {
    key: 'username',
    label: 'Performed by',
    sortable: true,
  },
  {
    key: 'activity',
    label: 'Action',
  },
  {
    key: 'value',
    label: '',
  },
];

const props = defineProps({
  employee: {
    type: Object,
    required: true,
  },
  history: {
    type: Object,
    required: true,
  },
  salaryHistory: {
    type: Array,
    required: true,
  },
});

const activityHistory = computed(() => {
  const activityHistory = props.history.activity.filter(
    item =>
      (HISTORY_ACTIVITIES.includes(item.activity) ||
        HISTORY_PROPERTIES.some(
          property => typeof item?.properties?.new === 'object' && property in item.properties.new,
        )) &&
      !(item.activity === HISTORY_KEYS.PROPSCHANGE && !item.username),
  );

  activityHistory.push(
    ...props.history.enrollment
      .filter(item => item.state !== EMPLOYEE_STATE.ENROLLING)
      .map(item =>
        merge(
          {
            activity: HISTORY_KEYS.STATUS_CHANGED,
            logged_at: item.created_at,
            properties: { state: item.state },
          },
          UNASSIGNED_ACTIVITY_DEFAULT_PROPERTIES,
        ),
      ),
  );

  activityHistory.push(
    ...props.salaryHistory
      .filter(item => item.new.salary && item.old.salary && item.new.salary != item.old.salary && item.username)
      .map(item =>
        merge(
          {
            activity: HISTORY_KEYS.SALARY_CHANGE,
            logged_at: item.created_at,
            username: item.username,
            properties: { new: item.new, old: item.old },
          },
          UNASSIGNED_ACTIVITY_DEFAULT_PROPERTIES,
        ),
      ),
  );

  if (props.employee.properties.optout_initiated_at) {
    activityHistory.push(
      merge(
        {
          activity: HISTORY_KEYS.OPTED_OUT,
          logged_at: props.employee.properties.optout_initiated_at,
          properties: { optout_reason: props.employee.properties.optout_reason },
        },
        UNASSIGNED_ACTIVITY_DEFAULT_PROPERTIES,
      ),
    );
  }

  return activityHistory.sort((a, b) => moment(b.logged_at).diff(a.logged_at));
});

const getHistoryItemLabel = item => {
  const key = getHistoryItemKey(item);

  return typeof HISTORY_LABELS[key] === 'function' ? HISTORY_LABELS[key](item) : HISTORY_LABELS[key] || '';
};

const getHistoryItemSummary = item => {
  const key = getHistoryItemKey(item);

  return typeof HISTORY_VALUES[key] === 'function' ? HISTORY_VALUES[key](item) : HISTORY_VALUES[key] || '';
};

const getHistoryItemKey = item =>
  item.properties?.new
    ? Object.keys(item.properties.new).find(key => HISTORY_PROPERTIES.includes(key)) || item.activity
    : item.activity;
</script>

import { render, staticRenderFns } from "./RaiseAdvertisment.vue?vue&type=template&id=37a89223&scoped=true&"
import script from "./RaiseAdvertisment.vue?vue&type=script&setup=true&lang=js&"
export * from "./RaiseAdvertisment.vue?vue&type=script&setup=true&lang=js&"
import style0 from "./RaiseAdvertisment.vue?vue&type=style&index=0&id=37a89223&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "37a89223",
  null
  
)

export default component.exports
<template>
  <app-page
    :title="createMode ? 'Create pay schedule' : `${isDisablePayscheduleEditing ? 'View' : 'Edit'} pay schedule`"
    icon="calendar-alt"
    :previous="[{ label: 'Payday', to: { name: 'sme-pay-day' } }]"
  >
    <template v-slot:infobox>
      <sme-info-box title="What is a pay schedule?" id="about-pay-schedule" save-state>
        <p>
          Pay schedules are how we know when pay days fall, how earnings accrue, and how to assign advances to pay
          cheques.
        </p>
        <ul>
          <li>Each employee is assigned to one pay schedule.</li>
          <li>You can have multiple pay schedules as required, e.g. one for shift workers and one for salaried.</li>
        </ul>
        <p v-if="!isDisablePayscheduleEditing">
          It is important to ensure your payschedules are correct and up to date. You can modify them at any time.
        </p>
      </sme-info-box>
    </template>

    <pay-schedule-form
      :create="createMode"
      :loading="loading"
      :paySchedule="payschedule"
      @create-pay-schedule="$event => onCreatePayschedule($event)"
      @update-pay-schedule="$event => onUpdatePayschedule($event)"
    >
      <template #before>
        <sme-alert
          v-if="!createMode && payschedule && isPayscheduleExpiring(payschedule.pay_schedule_id)"
          level="warning"
          class="mb-3"
        >
          Your pay schedule is running out of dates.
          <template v-if="isDisablePayscheduleEditing">
            Please contact <a href="mailto:client.support@wagestream.com">customer support</a> with your new dates.
          </template>
          <template v-else> You can add more dates below. </template>
        </sme-alert>
        <sme-alert v-if="errorMessage" level="danger" class="mb-3">{{ errorMessage }}</sme-alert>
      </template>
    </pay-schedule-form>
  </app-page>
</template>

<script setup>
import { ref, onBeforeMount, computed, getCurrentInstance } from 'vue';
import { useRoute, useRouter } from 'vue-router/composables';
import ApiClient from '@/ApiClient';
import AppPage from '@/components/AppPage.vue';
import SmeAlert from '@/components/atoms/SmeAlert.vue';
import SmeInfoBox from '@/components/atoms/SmeInfoBox.vue';
import useFeatureFlags from '@/composables/useFeatureFlags';
import PayScheduleForm from '@/pages/payschedule/components/PayScheduleForm.vue';
import State from '@/state/State.js';
import { isPayscheduleExpiring } from '@/utils/Payschedule';

const $router = useRouter();
const $route = useRoute();
const currentInstance = getCurrentInstance();
const { isDisablePayscheduleEditing } = useFeatureFlags();

const loading = ref(false);
const errorMessage = ref(false);
const payschedule = ref(null);

const createMode = computed(() => $route.params.payScheduleId === 'create');

const onUpdatePayschedule = async updatedPayschedule => {
  loading.value = true;
  errorMessage.value = '';
  try {
    payschedule.value = await ApiClient.updateSmePaySchedule(
      State.state.company.company_id,
      payschedule.value.pay_schedule_id,
      updatedPayschedule,
    );
    currentInstance.proxy.$appToast('Pay schedule updated', {
      title: `Success`,
      variant: 'success',
    });
  } catch (error) {
    errorMessage.value = error.message;
  }
  loading.value = false;
};

const onCreatePayschedule = async newPayschedule => {
  loading.value = true;
  errorMessage.value = '';
  try {
    payschedule.value = await ApiClient.createSmePaySchedule(State.state.company.company_id, newPayschedule);

    $router.push({ name: 'pay-schedule', params: { payScheduleId: payschedule.value.pay_schedule_id } });
    currentInstance.proxy.$appToast('Pay schedule created', {
      title: `Success`,
      variant: 'success',
    });
  } catch (error) {
    errorMessage.value = error.message;
  }
  loading.value = false;
};

onBeforeMount(async () => {
  loading.value = true;
  if ($route.params.payScheduleId !== 'create') {
    try {
      payschedule.value = await ApiClient.getSmePaySchedule(
        State.state.company.company_id,
        $route.params.payScheduleId,
      );
    } catch (error) {
      errorMessage.value = error.message;
    }
  }
  loading.value = false;
});
</script>

import axios from 'axios';
import queryString from 'query-string';
import Vue from 'vue';
import Router from 'vue-router';
import ApiClient, { isLoggedIn } from '@/ApiClient';
import State from '@/state/State';

import PageNotFound from '@/pages/PageNotFound.vue';
import AppSwitcher from '@/pages/AppSwitcher.vue';

import { publicRoutes, flexpayRoutes, flexworkRoutes, sharedRoutes } from '@/routes.js';

import {
  isFlexPayLike,
  isFlexWorkLike,
  isFlexBoth,
  isFlexEither,
  FLEXWORK_SIGNER_ONLY_ROUTES,
  isFlexWorkLikeIncludingSigner,
} from '@/guards.js';

Vue.use(Router);

const unauthorizedRoutes = publicRoutes.map(route => route.name);

const guardGenerator = condition => {
  return typeof condition === 'function' ? condition(router) : condition;
};

const addGuard = (route, guard) => {
  if (!route?.meta) {
    route.meta = {};
  }
  if (!route.meta?.guards) {
    route.meta.guards = [];
  }
  // eslint-disable-next-line no-unused-vars
  route.meta.guards.push(() => {
    return guard();
  });
  return route;
};

const generateRoutes = () => {
  const routes = [
    {
      path: '/',
      name: 'apps',
      component: AppSwitcher,
      meta: {
        guards: [],
      },
    },
  ];

  for (const route of publicRoutes) {
    routes.push(route);
  }

  for (const route of sharedRoutes) {
    routes.push(addGuard(route, isFlexEither));
  }

  for (const route of flexpayRoutes) {
    routes.push(addGuard(route, isFlexPayLike));
  }

  for (const route of flexworkRoutes) {
    if (FLEXWORK_SIGNER_ONLY_ROUTES.includes(route.name)) {
      routes.push(addGuard(route, isFlexWorkLikeIncludingSigner));
    } else {
      routes.push(addGuard(route, isFlexWorkLike));
    }
  }

  routes.push({
    path: '*',
    name: 'not-found',
    component: PageNotFound,
    meta: {
      guards: [],
    },
  });
  unauthorizedRoutes.push('not-found');

  // fix up guards by wrapping in a generator
  for (const route of routes) {
    if (route.meta?.guards) {
      route.meta.guards = route.meta.guards.map(g => guardGenerator.bind(null, g));
    }
  }

  return routes;
};

const router = new Router({
  mode: 'history',
  routes: generateRoutes(),
});

router.afterEach((to, from) => {
  router.history.previous = from;

  const data = {
    // route data like name and params
    // remove circular references
    to: { ...to, matched: undefined },
  };

  const urlParams = new URLSearchParams(window.location.search);
  data['qs'] = {};
  for (const [key, value] of urlParams) {
    data['qs'][key] = value;
  }

  ApiClient.postAnalytics('page_view', data);
});

export const getRouteNameFromClaims = claims => {
  if (isFlexBoth(claims)) {
    return 'apps';
  } else if (isFlexPayLike(claims)) {
    return 'sme-dashboard';
  } else if (isFlexWorkLike(claims)) {
    return 'talent-pool-root';
  } else {
    return 'not-found';
  }
};

const HASH_PATH_REGEX = /#(\/[^&?]*)/;
const HASH_SEARCH_REGEX = /\?(.*?)\/?(#|$)/;

const getPathFromHash = fullPath => HASH_PATH_REGEX.exec(fullPath)[1];

router.beforeEach((to, from, next) => {
  const fullPath = to.fullPath;
  let reformedPath;

  /*
  Some third parties redirect to the portal with malformed search strings, i.e. without the `?`, so for Vue to be able to
  understand the path correctly (extract params, etc.), we need to correct this.
  */
  if (fullPath.includes('&') && !fullPath.includes('?')) {
    reformedPath = fullPath.split(/&(.*)/s).filter(Boolean).join('?');
  }

  /*
  Because URL's may/will still reach the portal with hash URL's, e.g. /#/employees, we need to redirect to the equivalent
  non hash version, i.e. /employees. This also caters for the potential edge case with providers, where they may redirect
  to the portal with a full hash path, e.g. http://localhost?foo=bar#/integrations/deputy, which should be
  http://localhost/integrations/deputy?foo=bar.
  */
  if (fullPath.match(HASH_PATH_REGEX)) {
    reformedPath = reformedPath || fullPath;
    const search =
      new URL(window.location.origin + reformedPath).search?.replace('?', '') ||
      HASH_SEARCH_REGEX.exec(reformedPath)?.[1];

    reformedPath = getPathFromHash(reformedPath) + (search ? `?${search}` : '');
  }

  if (reformedPath) {
    next(reformedPath);
    return;
  }

  if (to?.meta?.guards && isLoggedIn()) {
    for (const guard of to.meta.guards) {
      const ok = guard();
      if (!ok) {
        next({ name: 'not-found', replace: true });
        return;
      }
    }
  }

  // Whether logged in or not, catch impersonation and redirect
  if (to.path === '/') {
    if (window.location.search.indexOf('?access_token=') !== -1) {
      next({ name: 'login-impersonate', query: queryString.parse(location.search) });
      return;
    }
  }

  // Guard against unauthorized access
  if (!isLoggedIn()) {
    // Check for referrals landing
    if (window.location.href.indexOf('via=') !== -1) {
      const existingReferralCode = localStorage.getItem('sme.registration.via');
      let referralCode = '';
      if (!existingReferralCode) {
        const m = window.location.href.match(/via=([A-Za-z0-9]+)/);
        if (m) {
          referralCode = m[1];
          localStorage.setItem('sme.registration.via', referralCode);
          if (process.env.VUE_APP_ANALYTICS_URL) {
            axios.post(process.env.VUE_APP_ANALYTICS_URL, {
              event: 'referral_land',
              params: { via: referralCode, href: window.location.href },
              user_id: '',
              msg: 'sme_engagement',
            });
          }
        }
      }
    }

    if (unauthorizedRoutes.includes(to.name)) {
      next();
      return;
    }

    const query = to.fullPath !== '/' ? { redirect: decodeURIComponent(to.fullPath) } : {};
    next({ name: 'login', query: query });
    return;
  }

  if (to.path !== from.path) {
    window.scrollTo(0, 0);
  }

  if (to.path === '/' && to.name !== 'not-found') {
    const name = getRouteNameFromClaims(State.state.claims);
    if (name !== to.name) {
      next({ name, replace: true });
      return;
    }
  }

  next();
});

export default router;

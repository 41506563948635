<template>
  <div v-if="loading" class="loading-container">
    <app-loading />
  </div>
  <div v-else class="wrapper">
    <div ref="stripeCardDom" class="stripe-element"></div>
    <div class="text-center">
      <b-button id="pay-button" variant="primary">
        <Lock class="lock" :color="PaletteColors['default-lighten-90']" width="20" height="20"></Lock>
        Unlock
      </b-button>
    </div>
    <b-alert class="small-alert" v-if="error" variant="danger" show>{{ error }}</b-alert>
  </div>
</template>

<script setup>
import { ref, onMounted } from 'vue';
import { loadStripe } from '@stripe/stripe-js';
import Lock from '@/assets/icons/Lock.vue';
import { PaletteColors } from '@/Theme';
import ApiClient from '@/ApiClient';
import AppLoading from '@/components/AppLoading.vue';
import tracker from '@/Tracker';

const props = defineProps({
  shortlist: Object,
});

const emit = defineEmits(['charged']);

const error = ref(null);
const loading = ref(true);
const stripeCardDom = ref(null);
const stripeCard = ref(null);
const cardDetails = ref(null);

onMounted(async () => {
  loading.value = true;
  const intent = await ApiClient.getShortlistUpgradeIntent(props.shortlist.talent_pool_posting_id);
  loading.value = false;

  const stripe = await loadStripe(process.env.VUE_APP_RAISE_STRIPE_PUB_KEY);

  stripeCard.value = stripe.elements().create('card', { hidePostalCode: true, disableLink: true });

  stripeCard.value.mount(stripeCardDom.value);

  stripeCard.value.addEventListener('change', event => {
    if (event.complete) {
      cardDetails.value = stripeCard.value;
    } else {
      // if the card number is not valid, do not emit a value
      cardDetails.value = null;
    }
  });

  if (intent.data.charged === true) {
    // This should be impossible but we might want to handle where
    // we have a card on file but they want to use another one.
    await tracker.trackAnonymousEngagement('shortlist_public_pay_anomaly', {
      posting_id: props.shortlist.talent_pool_posting_id,
    });
    emit('charged', null);
    return;
  }

  document.getElementById('pay-button').addEventListener('click', async () => {
    await tracker.trackAnonymousEngagement('shortlist_public_pay_click', {
      posting_id: props.shortlist.talent_pool_posting_id,
    });

    const response = await stripe.createToken(cardDetails.value);
    if (response?.error) {
      await tracker.trackAnonymousEngagement('shortlist_public_pay_error', {
        posting_id: props.shortlist.talent_pool_posting_id,
        message: response.error.message,
      });
      error.value = response.error.message;
      loading.value = false;
      return;
    }

    const token = response.token;
    const result = await stripe.confirmCardPayment(intent.data.client_secret, {
      payment_method: {
        card: {
          token: token.id,
        },
      },
    });

    loading.value = true;

    if (result.error) {
      await tracker.trackAnonymousEngagement('shortlist_public_pay_error', {
        posting_id: props.shortlist.talent_pool_posting_id,
        message: result.error.message,
      });
      error.value = result.error.message;
      loading.value = false;
      return;
    }

    // We dont care so much about result.paymentIntent.status as
    // even a succeeded intent has to pass through webhooks
    // back to us so we can unlock.
    let count = 0;
    const max = 15;
    const checkingStatus = async function () {
      if (count++ > max) {
        // something is wrong but may still have worked
        await tracker.trackAnonymousEngagement('shortlist_public_pay_timeout', {
          posting_id: props.shortlist.talent_pool_posting_id,
        });
        clearInterval(checking);
        window.location.reload();
      }
      try {
        const creds = await ApiClient.getShortlistUpgradeCredentials(props.shortlist.talent_pool_posting_id);
        if (creds.success !== true) {
          clearInterval(checking);
        }
        if (creds.data) {
          await tracker.trackAnonymousEngagement('shortlist_public_pay_success', {
            posting_id: props.shortlist.talent_pool_posting_id,
          });
          clearInterval(checking);
          emit('charged', creds.data);
        }
      } catch (e) {
        clearInterval(checking);
      }
    };
    let checking = setInterval(checkingStatus, 1000);
  });
});
</script>

<style scoped lang="scss">
.wrapper {
  margin: auto;
  width: 300px;
  padding-top: 50px;
  padding-bottom: 50px;
}
.stripe-element {
  padding-top: 10px;
  padding-bottom: 50px;
}
.prompt-text {
  padding-bottom: 30px;
}

.small-alert {
  margin-top: 20px;
}
</style>

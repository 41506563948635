<template>
  <sme-card class="account">
    <app-loading v-if="loading" />

    <router-link v-else-if="!funding" :to="{ name: 'sme-verify-ownership' }" class="account__locked-container">
      <img class="account__locked" src="@/assets/registration-locked.png" alt="locked" />
      <span>Verify identity to unlock</span>
    </router-link>

    <template v-else>
      <div class="account__balance-caption">Current Balance</div>
      <div class="account__balance-value">{{ toCurrency(funding.balance * 100) }}</div>

      <hr class="d-none d-lg-block" />

      <p class="d-none d-lg-block">
        <strong class="account__highlight">Make a deposit</strong><br />
        <span>To add funds to your account, please make a deposit to the following account by bank transfer.</span>
      </p>
      <p class="d-none d-lg-block">
        <span>Account Name</span><br />
        <span class="account__highlight">{{ fundingAccountName }}</span>
      </p>
      <p class="d-none d-lg-block">
        <span>Sort Code</span><br />
        <span class="account__highlight">{{ fundingSortCode }}</span>
      </p>
      <p class="d-none d-lg-block">
        <span>Account Number</span><br />
        <span class="account__highlight">{{ fundingAccountNumber }}</span>
      </p>

      <template v-if="showWithdrawLink">
        <hr class="d-none d-lg-block" />
        <router-link :to="{ name: 'sme-funds-withdraw' }">
          Do you want to withdraw from the current balance? &rarr;
        </router-link>
      </template>
    </template>
  </sme-card>
</template>

<script>
import ApiClient from '@/ApiClient';
import AppLoading from '@/components/AppLoading';
import SmeCard from '@/components/atoms/SmeCard.vue';
import State from '@/state/State';
import { toCurrency } from '@/utils';

export default {
  name: 'FundingAccount',
  components: {
    AppLoading,
    SmeCard,
  },
  props: {
    showWithdrawLink: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      toCurrency,
      error: undefined,
      loading: true,
      funding: null,
    };
  },
  async mounted() {
    try {
      this.loading = true;
      const { tasks } = await ApiClient.smeCompanyGetTaskList(State.state.company.company_id);
      const kycStatus = tasks.find(t => t.code === 'VERIFY_IDENTITY')?.status;
      this.funding = kycStatus === 'COMPLETE' ? await State.getFunding() : null;
    } catch (error) {
      this.error = error;
    } finally {
      this.loading = false;
    }
  },
  computed: {
    modulr() {
      return State.state.company.properties?.modulr;
    },
    fundingAccountName() {
      return State.state.company.properties.payment_name;
    },
    fundingSortCode() {
      return this.modulr?.payment_omnibus_sort_code || 'XX-XX-XX';
    },
    fundingAccountNumber() {
      return this.modulr?.payment_omnibus_account_number || 'XXXXXXXX';
    },
  },
};
</script>

<style scoped lang="scss">
.account__locked-container {
  align-items: center;
  display: flex;
  justify-content: center;
}

.account__locked {
  display: none;
  display: inline-block;
  width: 16px;
  margin-right: 8px;
}

.account__highlight {
  color: var(--palette-color-brand-primary);
}

.account hr {
  margin-right: -32px;
  margin-left: -32px;
}

.account__balance-value {
  text-align: right;
  font-weight: bold;
  font-size: 40px;
  color: var(--palette-color-brand-primary);
  @media (max-width: 768px) {
    text-align: left;
  }
}

.account__balance-caption {
  margin-top: 12px;
  margin-bottom: -8px;
  text-align: right;
  font-size: 20px;
  @media (max-width: 768px) {
    text-align: left;
  }
}
</style>

<template>
  <app-page
    title="Integrations"
    subtitle="Automatically update employees and shifts by integrating with your time and attendance provider"
    :error="error"
    icon="chart-network"
  >
    <template v-slot:custom-content>
      <b-col>
        <app-loading v-if="loading" />
        <template v-else-if="!canIntegrate">
          <sme-alert level="info" class="mb-3">
            Your integration is managed by your account manager. Please contact them to make changes.
          </sme-alert>
          <sync-results-list :syncResults="syncResults" />
        </template>
        <template v-else>
          <integration-info
            v-if="shouldDisplayEnabledIntegrations(fullEnabledIntegrationData) && !configuring"
            :enabledIntegrations="fullEnabledIntegrationData"
          />
          <integrations-select
            v-if="shouldDisplaySelectIntegrations(fullEnabledIntegrationData) && !configuring"
            :availableIntegrations="availableIntegrations"
            @configure-integration="configureIntegration"
          />
          <integrations-submit-api-credentials
            v-if="configuring"
            :provider="configuring.provider"
            :icon="configuring.icon"
            :companyId="state.company.company_id"
            secondaryCta="Back"
            @primary-cta="goBack"
            @secondary-cta="goBack"
          />
        </template>
      </b-col>
    </template>
  </app-page>
</template>

<script>
import { computed } from 'vue';
import ApiClient from '@/ApiClient';
import tracker from '@/Tracker';
import AppLoading from '@/components/AppLoading.vue';
import AppPage from '@/components/AppPage.vue';
import SmeAlert from '@/components/atoms/SmeAlert.vue';
import useFeatureFlags from '@/composables/useFeatureFlags';
import IntegrationInfo from '@/pages/integrations/components/IntegrationInfo.vue';
import IntegrationsSelect from '@/pages/integrations/components/IntegrationsSelect.vue';
import IntegrationsSubmitApiCredentials from '@/pages/integrations/components/IntegrationsSubmitApiCredentials.vue';
import SyncResultsList from '@/pages/integrations/components/SyncResultsList.vue';
import State from '@/state/State';
import { NON_CONFIGURABLE_INTEGRATIONS } from '@/pages/integrations/constants';

export default {
  name: 'Integrations',
  components: {
    AppPage,
    AppLoading,
    IntegrationsSubmitApiCredentials,
    IntegrationsSelect,
    IntegrationInfo,
    SmeAlert,
    SyncResultsList,
  },
  data() {
    return {
      loading: true,
      error: undefined,
      state: State.state,
      availableIntegrations: [],
      enabledIntegrations: [],
      fullEnabledIntegrationData: [],
      configuring: null,
      syncResults: [],
      enabledIntegrationIds: [],
    };
  },
  setup() {
    const { isSme, canViewIntegrationHistory, canIntegrate } = useFeatureFlags();
    return {
      isSme,
      canViewIntegrationHistory,
      canIntegrate,
    };
  },
  methods: {
    goBack() {
      this.$router.push({ name: 'sme-integrations' });
    },
    shouldDisplayEnabledIntegrations(integrations) {
      return integrations.length > 0;
    },
    shouldDisplaySelectIntegrations(integrations) {
      if (integrations.length === 0) {
        return true;
      } else if (integrations.length === 1 && integrations[0].provider == 'xero') {
        return true;
      } else {
        return false;
      }
    },
    async configureIntegration(integration) {
      if (integration.oauth2) {
        this.loading = true;
        await tracker.trackEvent('sme_activation_enable_integration');
        this.$router.push({
          name: 'sme-oauth2',
          params: { provider: integration.provider },
          query: { next: window.location.toString() },
        });
      } else if (NON_CONFIGURABLE_INTEGRATIONS.includes(integration.provider)) {
        this.loading = true;
        await tracker.trackEvent('sme_activation_enable_integration');
        await ApiClient.configureIntegration(integration.provider, this.state.company.company_id, {});
        this.$router.go();
      } else {
        if (this.$route.path === `/sme/integrations/${integration.provider}`) {
          this.configuring = integration;
          return;
        }
        this.$router.push({
          name: 'sme-integrations',
          params: { provider: integration.provider },
        });
      }
      this.configuring = integration;
    },
  },
  async mounted() {
    const availableIntegrations = await ApiClient.getAvailableSmeIntegrations();
    const enabledIntegrations = await ApiClient.getIntegrations(this.state.company.company_id);

    for (const integration of availableIntegrations) {
      const name = integration.provider.toUpperCase();
      integration.enabled = enabledIntegrations.data.find(x => {
        if (
          (x.partner.toLowerCase() === name.toLowerCase() ||
            (x.properties.partner && x.properties.partner.toLowerCase() === name.toLowerCase())) &&
          x.partner !== 'B2B_UPLOAD'
        ) {
          this.fullEnabledIntegrationData.push({ ...integration, ...x });
          return true;
        } else {
          return false;
        }
      });
    }

    const filteredIntegrations = availableIntegrations.filter(item => item.display);

    this.availableIntegrations = filteredIntegrations;
    this.enabledIntegrations = enabledIntegrations;
    this.enabledIntegrationIds = computed(() =>
      enabledIntegrations.data.map(integration => integration.company_integration_id),
    );

    this.syncResults = await Promise.all(
      this.enabledIntegrationIds.map(integration => ApiClient.getIntegrationResults(integration)),
    ).then(results =>
      results
        .flatMap(result => result.data)
        .filter(result => result.completed_at)
        .sort((a, b) => (a.completed_at < b.completed_at ? 1 : -1)),
    );

    if (this.$route.params.provider) {
      const integration = this.availableIntegrations.find(x => x.provider === this.$route.params.provider);
      if (integration) {
        await this.configureIntegration(integration);
      }
    }

    this.loading = false;
  },
  computed: {},
};
</script>

import { render, staticRenderFns } from "./ShiftsUpload.vue?vue&type=template&id=104f35d4&"
import script from "./ShiftsUpload.vue?vue&type=script&setup=true&lang=js&"
export * from "./ShiftsUpload.vue?vue&type=script&setup=true&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports
<template>
  <div class="role">
    <div class="role-name">{{ props.roleName }}</div>
    <div class="experience" v-if="!!experience?.wagestream_history?.length">
      <div v-for="history in props.experience?.wagestream_history" :key="history.opaque_id">
        <div class="heading">
          <span class="verified-title"
            >Wagestream verified
            <CheckCircleFilled :color="PaletteColors['brand-primary']" :height="18" :width="18"></CheckCircleFilled>
          </span>
          <span class="time"
            >{{
              formatDuration(
                history?.started_on || history.first_shift_seen,
                history?.terminated_at && history.terminated_at !== history.started_on
                  ? history.terminated_at
                  : history.last_shift_seen,
              )
            }}, {{ history.total_shifts }} shift{{ history.total_shifts === 1 ? '' : 's' }}</span
          >
        </div>
        <div class="company-tile">
          <img :src="getCompanyLogo(history)" class="logo" />
          <div>
            <span class="role-name">{{ history?.company }}</span>
            <span class="subheading">{{ history?.address }}</span>
          </div>
        </div>
        <div class="tags">
          <CompanyRoleBadge
            v-for="tag in getTags(history)"
            :key="tag"
            :role="tag"
            :verified="tag.includes('others') ? false : true"
          />
        </div>
      </div>
    </div>
    <div class="experience" v-if="!!experience?.raise_history?.length">
      <div>
        <div class="heading">
          <span>Raise experience</span>
          <span class="time"
            >{{ experience?.raise_history?.length || 0 }} shift{{
              experience?.raise_history?.length > 1 ? 's' : ''
            }}</span
          >
        </div>
        <EmployeeCompanyLogos :companyLogos="getRaiseLogos(experience)"></EmployeeCompanyLogos>
        <div class="role-name">
          {{ raiseLocations.slice(0, 4).join(', ') + (raiseLocations.length > 4 ? '...' : '') }}
        </div>
        <div class="subheading">
          <UserRatingDisplay :rating="getRaiseRating(experience.raise_history)"></UserRatingDisplay>
          {{ raiseLocations.length === 1 ? '1 location' : `${raiseLocations.length} locations` }}
        </div>
      </div>
    </div>
    <div class="experience" v-if="!!experience?.unverified_roles?.length">
      <div v-for="history in props.experience?.unverified_roles" :key="history.opaque_id">
        <div class="heading">
          <span>Unverified experience</span>
          <span class="time">{{ getUnverifiedHistoryDateRange(history) }}</span>
        </div>
        <div class="role-name">
          {{ history?.company_name }}
          <div>
            <div class="subheading">{{ history?.description }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import UserRatingDisplay from '@/pages/talent-pool/components/UserRatingDisplay.vue';
import { computed } from 'vue';
import EmployeeCompanyLogos from '@/pages/talent-pool/components/EmployeeCompanyLogos.vue';
import CompanyRoleBadge from '@/pages/talent-pool/components/CompanyRoleBadge.vue';
import { formatDuration } from '@/pages/talent-pool/utils/datetime';
import { formatDate } from 'date-fns';
import CheckCircleFilled from '@/assets/icons/CheckCircleFilled.vue';
import { PaletteColors } from '@/Theme';

const props = defineProps({
  experience: {
    type: Object,
    required: true,
  },
  roleName: {
    type: String,
    required: true,
  },
});

const raiseLocations = computed(() => Array.from(new Set(props.experience.raise_history?.map(raise => raise.company))));

const getRaiseLogos = experience => {
  return raiseLocations.value.map(location => {
    const info = experience.raise_history.find(raise => raise.company === location);
    return info?.brand?.logo_url || '';
  });
};

const getTags = role => {
  const tags = role?.site_tags?.flatMap(obj => obj && Object.values(obj));
  const uniqueTags = [...new Set(tags?.filter(tag => tag))];
  if (uniqueTags.length <= 4) {
    return uniqueTags;
  }
  return [...uniqueTags.slice(0, 4), `+ ${uniqueTags.length - 4} others`];
};

const getCompanyLogo = history => {
  const companyLogo = history?.brands[0]?.logo_url;
  if (companyLogo === 'https://s3.eu-west-2.amazonaws.com/uploads.wagestream.io/blank.png') {
    return '/talent-pool/default-company.png';
  }
  return companyLogo;
};

const getUnverifiedHistoryDateRange = history => {
  const startDate = new Date(history.start_year, history.start_month - 1);
  const endDate = history.end_month && history.end_year ? new Date(history.end_year, history.end_month - 1) : undefined;

  if (endDate) {
    return formatDuration(startDate.toISOString(), endDate.toISOString());
  }

  return `${formatDate(startDate, 'MMM yyy')} - now`;
};

const getRaiseRating = raiseHistory => {
  return raiseHistory.reduce((acc, curr) => acc + curr.shift_user_rating, 0) / raiseHistory.length;
};
</script>

<style scoped lang="scss">
.role-name {
  font-size: 18px;
  font-weight: 500;
  line-height: 26px;
  letter-spacing: -0.01em;
  text-align: left;
}

.subheading {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: -0.01em;
  text-align: left;
  color: var(--palette-color-default-lighten-20);
  margin-bottom: 0.25rem;
}

.experience {
  background-color: var(--palette-color-base-white);
  padding: 1rem;
  display: flex;
  flex-direction: column;
  border: 1px solid var(--palette-color-default-lighten-90);
  border-radius: 8px;
  gap: 2rem;
}

.heading {
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: -0.01em;
  text-align: left;
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;
  color: var(--palette-color-default-lighten-20);
  gap: 0.25rem;
}

.logo {
  height: 64px;
  width: 64px;
  border: 1px solid var(--palette-color-default-lighten-90);
  border-radius: 25rem;
  object-fit: cover;
  margin-bottom: 0.75rem;
  position: relative;
  display: inline-block;
}

.tags {
  margin-top: 0.5rem;
  display: flex;
  gap: 0.5rem;
  flex-wrap: wrap;
}

.time {
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: -0.01em;
  color: var(--palette-color-default-lighten-20);
  text-align: right;
}

.role {
  background-color: var(--palette-color-base-white);
  border-radius: 8px;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.company-tile {
  display: flex;
  margin: 0.5rem 0;
  gap: 0.5rem;
  align-items: center;
}

.verified-title {
  display: flex;
  gap: 0.25rem;
  align-items: flex-start;
}
</style>

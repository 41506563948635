const DEFAULT_PARTNERSHIP = 'wagestream';
const HOST_PARTNERSHIPS = {
  'portal.myviewpn.com': 'zellispay',
  'portal.financialwellbeing.webitrent.com': 'mhritrent',
  'portal.financialwellbeing.people-first.com': 'mhrpeoplefirst',
  'sme.wagestream.us': 'wagestreamus',
  'portal.wagestream.us': 'wagestreamus',
  'portal.staging.wagestream.us': 'wagestreamus',
};

/**
 * Any data that is specific to a partnership,
 * but not directly part of the visual theme.
 */
const PARTNERSHIP_DATA = {
  wagestream: {
    name: 'Wagestream',
    themeId: undefined,
  },
  zellispay: {
    name: 'MyView PayNow',
    themeId: 'zellis',
    featureFlags: {
      disable_integrated_employee_fields_strict: true,
      disable_landing_page_links: true,
      disable_partner_logos: true,
      disable_pay_with_wagestream: true,
      disable_payschedule_editing: true,
      disable_registration: true,
      disable_off_cycle_payments: true,
    },
  },
  mhritrent: {
    themeId: 'mhritrent',
    featureFlags: {
      disable_integrated_employee_fields_strict: true,
      disable_landing_page_links: true,
      disable_partner_logos: true,
      disable_pay_with_wagestream: true,
      disable_payschedule_editing: true,
      disable_registration: true,
      disable_off_cycle_payments: true,
    },
  },
  mhrpeoplefirst: {
    themeId: 'mhrpeoplefirst',
    featureFlags: {
      disable_integrated_employee_fields_strict: true,
      disable_landing_page_links: true,
      disable_partner_logos: true,
      disable_pay_with_wagestream: true,
      disable_payschedule_editing: true,
      disable_registration: true,
      disable_off_cycle_payments: true,
    },
  },
  wagestreamus: {
    themeId: 'wagestreamus',
    featureFlags: {
      disable_landing_page_links: true,
      disable_partner_logos: true,
      disable_pay_with_wagestream: true,
      disable_payschedule_editing: true,
      disable_registration: true,
      disable_off_cycle_payments: true,
      enable_employee_pause_scheduling: true,
    },
  },
};

/**
 * Gets the id of the current partnership, based on either the hostname
 * or a setting in localStorage (for development purposes). Falls back
 * to 'wagestream' if not defined.
 */
export function getPartnership() {
  const { hostname } = window.location;
  const hostPartnership = HOST_PARTNERSHIPS[hostname];

  return hostPartnership || localStorage.getItem('partnership') || DEFAULT_PARTNERSHIP;
}

/**
 * Gets data specific to the current partnership.
 */
export function getPartnershipData() {
  const partnership = getPartnership();
  const data = PARTNERSHIP_DATA[partnership];

  if (!data) {
    console.error('Partnership data undefined');
  }

  return data || {};
}
